body,
table,
input,
textarea,
select {
  @include body-type;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;

  &.mce-content-body {
    font-size: $body-font-size;
  }
}

a {
  color: inherit;
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

p {
  margin: 0 0 1em;

  @include mobile-only {
    margin: 0 0 1em;
  }
  > a {
    @include stealth-link;
  }
}

.text {
  a {
    color: $link-color;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
h3 {
  @include untitled(18px);
  line-height: 1.2222222222;
}
h4 {
  margin-top: 0;
}
strong {
  font-weight: 500;
}

hr {
  border-color: $border-color;
}

.text {
  p {
    &:last-child {
      margin: 0;
    }
  }
}
