.goulburn-event {
  .event-wrap {
    @include desktop-only {
      height: 100%;
    }
  }
}

.event-list-wrap,
.event-wrap {
  @include desktop-only {
    position: relative;

    &:after {
      position: absolute;
      content: "";
      background: $page-bottom-bar;
      @include ib-grid(24, $gutter: 0);
      @include suffix(12, $gutter: 0);
      left: 0;
      bottom: 0;
      padding-top: percentage(2 / $total-cols);

      .state-footer-resizing & {
        opacity: 0;
      }
    }
  }
}

.event-main-wrap {
  @include desktop-only {
    position: relative;
  }
}

.event-thumbs-list {
  @include desktop-only {
    position: fixed;
    @include ib-grid(6, $gutter: 0);
    top: #{$header-height * 3};
    left: 0;
    z-index: 1;
    will-change: transform;

    .state-header-slim & {
      top: #{$header-slim-height * 3};
    }
    .thumb-listing {
      display: none;
      transition: 0.25s transform;
      will-change: transform;

      .state-header-collapsed & {
        transform: translateY(-#{$header-height});
      }
      .state-header-collapsed.state-header-slim & {
        transform: translateY(-#{$header-slim-height});
      }
      &.active {
        display: block;
      }
    }
  }
  @include mobile-only {
    display: none;
  }
}

.event-list {
  .event-listing {
    color: $black-dark;
    border-top: 1px solid $border-color;

    &:hover {
      color: $green-medium;
    }
    h4 {
      @include untitled(13px);
      font-weight: 500;
      line-height: 1.3846153846;
      color: $green-medium;
    }
    .details {
      @include untitled(14px, $mobile-font-max: 11px);
      font-weight: 400;

      span {
        line-height: 1.3571428571;
      }
    }
  }
}

.event-list-wrap {
  background: $white;

  @include mobile-only {
    background: $grey-light;
  }
  a {
    @include untitled(14px, $mobile-font-max: 12px);
    font-weight: 500;
    line-height: 1.3846153846;
    color: $green-medium;
  }
  h2 {
    @include untitled(30px, $mobile-font-max: 28px);
    font-weight: 500;
    line-height: 1.3333333333;
  }
  .event-list-inner {
    background: $grey-light;
  }
  .event-listing {
    .details {
      @include untitled(14px, $mobile-font-max: 11px);
    }
  }
  .archive-wrap {
    a {
      @include stealth-link($weight: 0.06em);
    }
  }
  @include desktop-only {
    .event-list-inner {
      @include ib-grid(34, $gutter: 0);
      padding: 50px 0 260px;

      & > h2,
      .event-list,
      .archive-wrap {
        @include ib-grid(26, 34);
        @include prefix(7, 34);
      }
      & > h2 {
        margin-top: 0;
        margin-bottom: 5px;

        &.subtitle {
          margin-bottom: 35px;
        }
      }
      .event-list {
        h2 {
          margin: 100px 0 15px;

          &:first-child {
            margin-top: 0;
          }
        }
        .event-listing {
          display: block;
          margin-bottom: 20px;
          padding-top: 10px;

          &:last-child {
            margin-bottom: 0;
          }
          h4 {
            margin: 0 0 5px;
          }
          .details {
            .category {
              @include ib-grid(5.5, 26, $gutter: 0);
            }
            .date {
              @include ib-grid(7, 26);
            }
            .time {
              @include ib-grid(3.5, 26);
            }
            .location {
              @include ib-grid(10, 26);
            }
          }
        }
      }
      .archive-wrap {
        margin-top: 100px;

        h2 {
          margin: 0 0 15px;
        }
      }
    }
  }
  @include mobile-only {
    .event-list-inner {
      @include ib-grid(30, $gutter: 0);
      @include prefix(3);
      padding: 50px 0 155px;

      & > h2 {
        margin-top: 0;
        margin-bottom: 5px;
      }
      .event-list {
        h2 {
          margin: 60px 0 35px;

          &:first-child {
            margin-top: 0;
          }
        }
        .event-listing {
          display: block;
          margin-bottom: 30px;
          padding-top: 5px;

          &:last-child {
            margin-bottom: 0;
          }
          h4 {
            margin: 0 0 5px;
          }
          .details {
            .category,
            .time {
              display: none;
            }
            .date {
              display: block;
            }
            .location {
              @include ib-grid(24.5, 30);
            }
          }
        }
      }
      .archive-wrap {
        margin-top: 85px;

        h2 {
          margin: 0 0 35px;
        }
      }
    }
  }
}

.event-wrap {
  .event-detail-wrap {
    background: $grey-light;

    a {
      @include untitled(13px, $mobile-font-max: 12px);
      font-weight: 500;
      line-height: 1.3846153846;
      color: $green-medium;
    }
    h2 {
      @include untitled(30px, $mobile-font-max: 24px);
      font-weight: 500;
      line-height: 1.3333333333;
    }
    .detail-wrap {
      & > a {
        @include stealth-link($weight: 0.06em);
      }
      .title-wrap {
        h3 {
          @include untitled(30px, $mobile-font-max: 24px);
          font-weight: 400;
          line-height: 1.1666666667;
        }
        @include mobile-only {
          p {
            @include untitled(24px, $mobile-font-max: 24px);
            font-weight: 400;
            line-height: 1.1666666667;
          }
        }
      }
      .info-wrap {
        @include untitled(18px, $mobile-font-max: 14px);
        font-weight: 500;
        line-height: 1.3888888889;

        .start,
        .end {
          text-transform: lowercase;
        }
      }
      .booking-wrap {
        a {
          background: $black-true;
          color: $white;
          text-align: center;
        }
      }
    }

    @include desktop-only {
      position: relative;
      @include ib-grid(30, $gutter: 0);
      @include prefix(6, $gutter: 0);
      height: 100%;
      min-height: 850px;

      .image-wrap {
        position: fixed;
        @include ib-grid(11, $gutter: 0);

        &.bottom {
          position: absolute;
          @include ib-grid(11, 30, $gutter: 0);
          bottom: 500px;
          margin-bottom: percentage(2 / 30);
        }
        &.no-fix {
          position: absolute;
          @include ib-grid(11, 30, $gutter: 0);
          top: 0;
          bottom: auto;
        }
        &:before {
          position: absolute;
          @include ib-grid(7, 11, $gutter: 0);
          top: 0;
          right: 100%;
          height: 100%;
          content: "";
          background: $green-dark;
        }
      }
      .detail-wrap {
        @include ib-grid(14, 30);
        @include prefix(12, 30);
        padding-top: 70px;
        padding-bottom: 180px;

        .title-wrap {
          margin-bottom: 55px;

          h2 {
            margin: 0 0 5px;
          }
          h3 {
            margin: 0 0 10px;
          }
          p {
            margin: 0;
          }
        }
        .info-wrap {
          margin-bottom: 30px;

          p {
            margin: 0;
          }
        }
        .text-wrap {
          margin-bottom: 25px;
        }
        .booking-wrap {
          margin: 40px 0 25px;

          a {
            display: inline-block;
            padding: 15px 30px;
          }
        }
      }
    }
    @include mobile-only {
      position: relative;
      padding-top: percentage(33 / $total-cols);

      &:before {
        position: absolute;
        @include ib-grid(33, $gutter: 0);
        top: 0;
        left: 0;
        padding-top: percentage(33 / $total-cols);
        background: $green-dark;
        content: "";
      }
      & > .image-wrap {
        position: absolute;
        @include ib-grid(33, $gutter: 0);
        @include prefix(3, $gutter: 0);
        top: 0;
        left: 0;

        .caption {
          display: none;
        }
      }
      .detail-wrap {
        @include ib-grid(30);
        @include prefix(3);
        padding-top: 30px;
        padding-bottom: 130px;

        .title-wrap {
          margin-bottom: 30px;

          h2 {
            margin: 0 0 5px;
          }
          h3 {
            margin: 0 0 5px;
          }
          p {
            margin: 0;
          }
        }
        .info-wrap {
          margin-bottom: 32px;

          p {
            margin: 0;
          }
        }
        .text-wrap {
          margin-bottom: 25px;
        }
        .booking-wrap {
          margin: 40px 0 25px;

          a {
            display: inline-block;
            padding: 20px 50px;
          }
        }
      }
    }
  }
}
