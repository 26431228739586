.cart-wrap {
  @include desktop-only {
    position: absolute;
    width: 100%;
    transform: translateY(0);
    transition: transform 0.25s ease;
    display: none;
    padding-top: 48px;
    z-index: 3;
    pointer-events: none;
    will-change: transform;

    .state-cart-fixed & {
      position: fixed;
      top: $header-height;
    }
    .state-cart-fixed.state-header-slim & {
      top: $header-slim-height;
    }
    .state-cart-fixed.state-header-collapsed & {
      transform: translateY(-#{$header-height});
    }
    .state-cart-fixed.state-header-collapsed.state-header-slim & {
      transform: translateY(-#{$header-slim-height});
    }
    body.section-store &,
    article.shoptools-cart-page & {
      display: block;
    }
  }
  @include mobile-only {
    position: absolute;
    width: 100%;
    transform: translateY(0);
    transition: transform 0.25s ease;
    top: 0;
    left: 0;
    padding-top: 48px;
    z-index: 3;
    pointer-events: none;
    will-change: transform;
    overflow: hidden;

    .state-cart-mobile-fixed & {
      position: fixed;
      top: $header-mobile-height;
    }
    body.section-store &,
    article.shoptools-cart-page & {
      display: block;
    }
  }
  .status-wrap {
    background: $cart-color;
    transition: color 0.25s;

    &.link-only a.current,
    .product-title a:hover,
    .counter-wrap:hover {
      color: rgba($black-dark, 0.6);
    }
    .product-title {
      @include mobile-only {
        @include untitled(12px);
      }
    }
    .counter-wrap {
      cursor: pointer;
      user-select: none;

      @include mobile-only {
        @include untitled(12px);
      }
    }
    @include desktop-only {
      position: absolute;
      @include ib-grid(34, $gutter: 0);
      top: 0;
      left: 0;
      transition: transform 0.5s;
      transform: translateY(-100%);
      z-index: 5;
      pointer-events: all;

      &:before {
        position: absolute;
        content: "";
        background: $cart-color;
        width: 100%;
        height: 15px;
        bottom: 100%;
        left: 0;
      }
      &:after {
        position: absolute;
        background: $grey-light;
        @include ib-grid(2, 34, $gutter: 0);
        content: "";
        top: 0;
        left: 100%;
        height: 100%;
      }
      &.link-only {
        .counter-wrap {
          .arrow-wrap {
            display: none;
          }
        }
      }
      body.section-store & {
        transform: translateY(0%);
      }
      .product-title {
        @include ib-grid(25, 34);
        @include prefix(1, 34);
        padding: 15px 0;
      }
      .counter-wrap {
        position: relative;
        @include ib-grid(5, 34);
        @include prefix(1, 34);
        padding: 15px 0;

        .image-wrap {
          position: absolute;
          top: 50%;
          right: calc(100% + 10px);
          width: 18px;
          transform: translateY(-50%);
        }
        .count {
          @include ib-grid(7, 8);
        }
        .arrow-wrap {
          position: absolute;
          width: 18px;
          top: 50%;
          left: 100%;
          transform: translateY(-50%);

          .state-cart-open & {
            transform: translateY(-50%) rotateX(180deg);
          }
          .state-cart-empty & {
            display: none;
          }
        }
      }
    }
    @include mobile-only {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      transition: transform 0.5s;
      transform: translateY(-100%);
      z-index: 5;
      pointer-events: all;

      body.section-store & {
        transform: translateY(0%);
      }
      .product-title {
        @include ib-grid(19, $gutter: 0);
        @include prefix(1);
        padding: 15px 0;
      }
      .counter-wrap {
        position: relative;
        @include ib-grid(14, $gutter: 0);
        @include prefix(1);
        padding: 15px 0;

        .image-wrap {
          position: absolute;
          top: calc(50% - 2px);
          left: 0;
          width: 18px;
          transform: translateY(-50%);
        }
        .count {
          @include ib-grid(14, 14);
          padding-left: 28px;
        }
        .arrow-wrap {
          position: absolute;
          width: 12px;
          top: 50%;
          right: 0;
          transform: translateY(-50%);

          .state-cart-open & {
            transform: translateY(-50%) rotateX(180deg);
          }
          .state-cart-empty & {
            display: none;
          }
        }
      }
    }
  }
  .cart-close-bkg {
    background: rgba($grey-light, 0.9);
    cursor: pointer;

    @include desktop-only {
      position: fixed;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      transition: opacity 0.5s;
      will-change: opacity;
      pointer-events: none;
      opacity: 0;

      .state-cart-open & {
        pointer-events: all;
        opacity: 1;
      }
    }
  }
  .cart-snippet {
    background: $grey-light;

    & > h3 {
      @include untitled(30px);
    }
    .subtotal {
      @include untitled(18px);

      strong {
        font-weight: 400;
      }
      p {
        @include untitled(10px);
      }
    }
    @include desktop-only {
      width: 100%;
      overflow-y: auto;
      padding-bottom: 70px;
      transition: transform 0.5s;
      will-change: transform;
      transform: translateY(-150%);
      pointer-events: all;
      max-height: calc(100vh - 184px);

      .state-cart-open & {
        transform: translateY(0%);
      }
      & > h3 {
        @include ib-grid(32);
        @include prefix(2);
      }
      .checkout-item-list {
        @include ib-grid(34, $gutter: 0);
      }
      .subtotal {
        @include ib-grid(15.5, $gutter: 0);
        @include prefix(18.5, $gutter: 0);
        margin-bottom: 20px;

        strong {
          display: inline-block;
          margin-bottom: 5px;
        }
        p {
          margin: 0;
        }
      }
      .checkout-buttons {
        @include prefix(18.5, $gutter: 0);
      }
    }
    @include mobile-only {
      width: 100%;
      overflow-y: auto;
      padding-bottom: 70px;
      transition: transform 0.5s;
      will-change: transform;
      transform: translateY(-150%);
      pointer-events: all;
      height: calc(100vh - #{$header-mobile-height + $cart-mobile-height});
      -webkit-overflow-scrolling: touch;

      .state-cart-open & {
        transform: translateY(0%);
      }
      & > h3 {
        @include ib-grid(30, $gutter: 0);
        @include prefix(3);
        @include suffix(3);
      }
      .subtotal {
        @include ib-grid(30, $gutter: 0);
        @include prefix(3);
        @include suffix(3);
        margin-bottom: 20px;

        strong {
          display: inline-block;
          margin-bottom: 5px;
        }
        p {
          margin: 0;
        }
      }
      .checkout-buttons {
        @include ib-grid(30, $gutter: 0);
        @include prefix(3);
        @include suffix(3);
      }
    }
  }
}

.checkout-buttons {
  input[type="submit"],
  .button {
    @include shop-button-style;
  }
  @include desktop-only {
    @include ib-grid(8, 34, $gutter: 0);
  }
}

.shoptools-wrap {
  h3 {
    @include untitled(30px);
  }
  @include desktop-only {
    @include ib-grid(34, $gutter: 0);
    padding-top: 40px;

    & > h3,
    .checkout-empty {
      margin: 0 0 15px;
      @include ib-grid(29, 34, $gutter: 0);
      @include prefix(5, 34, $gutter: 0);

      .shoptools-cart-page & {
        @include ib-grid(32, 34, $gutter: 0);
        @include prefix(2, 34, $gutter: 0);
      }
    }
    .message {
      margin: 0 0 20px;
      @include ib-grid(29, 34, $gutter: 0);
      @include prefix(5, 34, $gutter: 0);
    }
  }
  @include mobile-only {
    & > h3,
    .checkout-empty {
      @include ib-grid(28);
      @include prefix(3);
      @include suffix(3);
      margin-bottom: 15px;
    }
    .message {
      @include ib-grid(28);
      @include prefix(3);
      @include suffix(3);
      margin-bottom: 20px;
    }
  }
  .checkout-order-status {
    @include untitled(16px);

    @include desktop-only {
      @include ib-grid(29, 34, $gutter: 0);
      @include prefix(5, 34, $gutter: 0);
      margin-bottom: 40px;

      :last-child {
        margin: 0;
      }
    }
    @include mobile-only {
      @include ib-grid(28);
      @include prefix(3);
      @include suffix(3);
      margin-bottom: 25px;

      :last-child {
        margin: 0;
      }
    }
  }
  .checkout-order-detail {
    @include untitled(14px);

    @include desktop-only {
      @include ib-grid(29, 34, $gutter: 0);
      @include prefix(5, 34, $gutter: 0);
      margin-bottom: 15px;

      .shipping-address,
      .billing-address {
        @include ib-grid(8, 29, $gutter: 0);
        @include suffix(1, 29);

        &:last-child {
          margin-right: 0;
        }
        :last-child {
          margin: 0;
        }
      }
    }
    @include mobile-only {
      @include ib-grid(28);
      @include prefix(3);
      @include suffix(3);

      .shipping-address,
      .billing-address {
        margin-bottom: 15px;

        :last-child {
          margin: 0;
        }
      }
    }
  }
  .checkout-totals {
    @include untitled(18px);

    strong {
      font-weight: 400;
    }
    .subtotal,
    .shipping {
      border-bottom: 1px solid $black;
    }
    @include desktop-only {
      @include ib-grid(16, 34, $gutter: 0);
      @include prefix(18, 34, $gutter: 0);
      margin-bottom: 25px;

      .subtotal,
      .shipping {
        padding: 12px 0;

        strong {
          @include ib-grid(7, 16);
          @include suffix(1, 16);
        }
      }
      .total {
        @include ib-grid(8, 16);
        @include prefix(8, 16);
        margin-top: 20px;
      }
    }
    @include mobile-only {
      @include ib-grid(28);
      @include prefix(3);
      @include suffix(3);
      margin-bottom: 15px;

      .subtotal,
      .shipping {
        padding: 10px 0;

        strong {
          @include ib-grid(12, 28);
        }
      }
      .total {
        margin-top: 10px;
        margin-bottom: 25px;

        strong {
          @include ib-grid(12, 28);
        }
      }
    }
  }
  .checkout-buttons {
    input[type="submit"],
    .button {
      @include shop-button-style;
    }
    @include desktop-only {
      @include ib-grid(8, 34, $gutter: 0);
      @include prefix(26, 34, $gutter: 0);
    }
    @include mobile-only {
      @include ib-grid(28);
      @include prefix(3);
      @include suffix(3);
    }
  }
  .checkout-form {
    & > h3 {
      @include untitled(30px);
    }
    @include desktop-only {
      padding-top: 48px;

      & > h3 {
        margin: 0 0 30px;
        @include ib-grid(29, 34);
        @include prefix(5, 34);
      }
      & > hr {
        @include ib-grid(29, 34);
        @include prefix(5, 34);
      }
      .shipping-address,
      .billing-address,
      .extra-address {
        @include ib-grid(15, 34, $gutter: 0);
        @include prefix(19, 34, $gutter: 0);
      }
      .extra-address {
        margin-bottom: 30px;
      }
      .billing-address {
        margin-bottom: 50px;

        &.disabled {
          display: none;

          + .checkout-buttons {
            margin-top: 50px;
          }
        }

        .billing-address-inner {
          display: none;

          h3 {
            margin: 0 0 16px;
          }
        }
        .form-field {
          &.billing_is_shipping {
            margin-top: 16px;
            margin-bottom: 16px;
          }
        }
      }
    }
    @include mobile-only {
      @include ib-grid(30);
      @include prefix(3);
      @include suffix(3);
      padding-top: 35px;

      & > h3 {
        margin: 0 0 20px;
      }
      .extra-address {
        margin-bottom: 20px;
      }
      .billing-address {
        margin-bottom: 40px;

        &.disabled {
          display: none;

          + .checkout-buttons {
            margin-top: 40px;
          }
        }

        .billing-address-inner {
          display: none;

          h3 {
            margin: 0 0 12px;
          }
        }
        .form-field {
          &.billing_is_shipping {
            margin-top: 12px;
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}

.checkout-item-list {
  .description {
    @include untitled(18px);
  }
  .form-row {
    border-top: 1px solid $border-color;

    &:last-child {
      border-bottom: 1px solid $border-color;
    }
    .image-wrap {
      .image {
        &.blank {
          img {
            background: $grey-dark;
          }
        }
      }
    }
    .quantity {
      @include cart_add_form;

      input[type="submit"] {
        @include untitled(12px, $mobile-font-max: 12px);
        background: none;
        border: 0;
        outline: none;
      }
      .form-field {
        label {
          @include untitled(12px);
        }
      }
    }
    .line-total {
      @include untitled(12px);
    }
    .remove {
      input[type="submit"] {
        @include untitled(12px);
        background: none;
        border: 0;
        outline: none;
        text-decoration: underline;
      }
    }
  }

  @include desktop-only {
    margin-bottom: 25px;

    p {
      margin: 0;
    }
    .form-row {
      position: relative;
      @include ib-grid(29, 34, $gutter: 0);
      @include prefix(5, 34, $gutter: 0);
      padding: 10px 0;

      &.has-image {
        @include ib-grid(32, 34, $gutter: 0);
        @include prefix(2, 34, $gutter: 0);
        display: inline-flex;
        padding: 15px 0;

        .image-wrap {
          @include ib-grid(4.5, 32, $gutter: 0);

          .image {
            &.blank {
              padding-top: 75.41899441%;
            }
          }
        }
        .info-wrap {
          position: relative;
          @include ib-grid(24, 32, $gutter: 0);
          @include prefix(0.5, 32, $gutter: 0);

          .description {
            @include ib-grid(19, 24);
            @include suffix(5, 24);
          }
          .quantity {
            @include ib-grid(4.5, 24);
            vertical-align: middle;
          }
          .line-total {
            @include ib-grid(19, 24);
            @include prefix(0.5, 24);
            vertical-align: middle;
          }
          .remove {
            position: absolute;
            @include ib-grid(19, 24);
            @include suffix(5, 24);
            bottom: 0;
            left: 0;

            input[type="submit"] {
              padding: 0;
            }
          }
        }
      }
      .description {
        @include ib-grid(29, 29);
        margin-bottom: 5px;
      }
      .quantity {
        @include ib-grid(4.5, 29);
        vertical-align: middle;

        input[type="submit"] {
          display: none;
        }
        .form-field {
          label {
            display: inline-block;
            margin-right: 5px;
            vertical-align: middle;
          }
          .input-wrap {
            @include ib-grid(2.5, 4.5);
            box-sizing: border-box;
            vertical-align: middle;
          }
        }
      }
      .line-total {
        @include ib-grid(19, 29);
        @include prefix(0.5, 29);
        vertical-align: middle;
      }
      .remove {
        display: none;
      }
    }
  }
  @include mobile-only {
    margin-bottom: 15px;

    p {
      margin: 0;
    }
    .form-row {
      position: relative;
      @include ib-grid(28);
      @include prefix(3);
      @include suffix(3);
      padding: 10px 0;

      &.has-image {
        padding: 15px 0;

        .image-wrap {
          @include ib-grid(12, 28);

          .image {
            &.blank {
              padding-top: 75.41899441%;
            }
          }
        }
        .info-wrap {
          position: relative;
          @include ib-grid(14, 28);
          @include prefix(2, 28);

          .quantity {
            margin-bottom: 15px;
            label {
              display: none;
            }
            .form-field {
              @include ib-grid(7, 14);
              vertical-align: middle;
            }
            input[type="submit"] {
              @include ib-grid(6, 14);
              @include prefix(1, 14);
              vertical-align: middle;
              padding: 0;
              text-align: left;
            }
          }
          .line-total {
            @include ib-grid(7, 14);
          }
          .remove {
            @include ib-grid(6, 14);
            @include prefix(1, 14);

            input[type="submit"] {
              padding: 0;
            }
          }
        }
      }
      .description {
        margin-bottom: 15px;
      }
      // .quantity {
      //   @include ib-grid(4.5, 29);
      //   vertical-align: middle;
      //
      //   input[type="submit"] {
      //     display: none;
      //   }
      //   .form-field {
      //     label {
      //       display: inline-block;
      //       margin-right: 5px;
      //       vertical-align: middle;
      //     }
      //     .input-wrap {
      //       @include ib-grid(2.5, 4.5);
      //       box-sizing: border-box;
      //       vertical-align: middle;
      //     }
      //   }
      // }
      // .line-total {
      //   @include ib-grid(19, 29);
      //   @include prefix(0.5, 29);
      //   vertical-align: middle;
      // }
      .remove {
        display: none;
      }
    }
  }
}

article.shoptools-cart-page,
article.shoptools-success-page {
  .shoptools-wrap {
    @include desktop-only {
      min-height: 850px;
      padding-top: 78px;
      padding-bottom: 70px;

      & > h3 {
        margin-bottom: 30px;
      }
    }
    @include mobile-only {
      padding-top: 45px;
      padding-bottom: 30px;

      & > h3 {
        @include ib-grid(28);
        @include prefix(3);
        @include suffix(3);
        margin-bottom: 20px;
      }
    }
  }
}

article.shoptools-cart-page {
  .shoptools-wrap {
    @include desktop-only {
      .selection-forms {
        @include ib-grid(16, 34);
        @include prefix(18, 34);

        form {
          input[type="submit"] {
            display: none;
          }
        }
      }
    }
    @include mobile-only {
      .selection-forms {
        @include ib-grid(28);
        @include prefix(3);
        @include suffix(3);

        form {
          input[type="submit"] {
            display: none;
          }
        }
      }
    }
  }
}

article.shoptools-checkout-page {
  .shoptools-wrap {
    @include desktop-only {
      padding-top: 30px;
      padding-bottom: 400px;
    }
    @include mobile-only {
      padding-top: 20px;
      padding-bottom: 200px;
    }
  }
}

#payform {
  #api-container {
    @include untitled(14px);
    padding: 0;
    max-width: none;

    label {
      @include untitled(12px);
      font-weight: 400;
      line-height: 1.3333333333;
    }
    #api-form {
      margin: 0;

      .api-row {
        margin: 0;

        &:last-child {
          .api-node {
            border-bottom: 0;
          }
        }
        .api-col-sm-6,
        .api-col-sm-12 {
          display: block;
          width: 100%;
          padding: 0;
        }
      }
    }
    .api-node {
      border-bottom: 1px solid $black;
      margin-bottom: 0;

      label {
        @include untitled(10px);
        cursor: pointer;
        pointer-events: none;

        @include mobile-only {
          @include untitled(12px);
        }
      }
      .api-text {
        @include untitled(18px);
        line-height: 1.29143;
        padding: 0.5em 0;
        background: inherit;
        border: 0;
        box-shadow: none;
        border-radius: 0;
        color: $black-dark;
        height: auto;
      }
      .api-button {
        @include shop-button-style;
        margin-top: 30px;
      }
    }
  }
  @include desktop-only {
    #api-container {
      @include ib-grid(15, 34, $gutter: 0);
      @include prefix(19, 34, $gutter: 0);

      .api-node {
        position: relative;

        label {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate3d(calc(-100% - 25px), -50%, 0);
        }
        .api-button {
          @include ib-grid(8, 15, $gutter: 0);
          @include prefix(7, 15, $gutter: 0);
        }
      }
    }
  }
  @include mobile-only {
    #api-container {
      .api-node {
        label {
          margin-top: 0.5em;
        }
      }
    }
  }
}
