header#header {
  @include grid-container;
  color: $nav-text-color;

  body.p404 & {
    display: none !important;
  }
  .header-bkg {
    background: $header-color;
  }
  .header-side-bkg {
    background: $green-dark;
  }
  .header-inner > .nav-wrap {
    a {
      &.child-current,
      body.section-program &.program,
      body.section-visit &.visit,
      body.section-education &.education,
      body.section-store &.store,
      body.section-about &.about,
      body.section-collection &.collection {
        span {
          @include text-link($color: rgba($black, 0.3), $weight: 0.06em);
        }
      }
      &:hover {
        span {
          @include stealth-link($color: rgba($black, 0.3), $weight: 0.06em);
        }
      }
    }
    .main-nav {
      a {
        @include untitled(20px);

        @include desktop-small-only {
          @include untitled(17px);
        }

        @include desktop-ipad-only {
          @include untitled(16px);
        }

        .state-header-slim & {
          @include untitled(16px);
        }
      }
    }
    .sub-nav {
      a {
        @include untitled(13px);

        .state-header-slim & {
          @include untitled(12px);
        }
      }
    }
  }
  @include desktop-only {
    position: fixed;
    width: 100%;
    z-index: 15;

    .header-inner {
      position: absolute;
      @include ib-grid(34, $gutter: 0);
      height: $header-height;
      left: 0;
      top: 0;
      transition: transform 0.25s;
      will-change: transform, height;
      z-index: 10;

      .state-header-slim & {
        height: $header-slim-height;
      }
      .state-header-collapsed & {
        transform: translateY(-100%);
      }
      .header-bkg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
      .feat-exhibition {
        position: absolute;
        @include ib-grid(2, 34, $gutter: 0);
        height: 200%;
        bottom: 0;
        left: 100%;
        z-index: 15;

        &:after {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: $featured-exhibition-overlay;
        }
      }
      .header-side-bkg {
        position: absolute;
        @include ib-grid(6, 34, $gutter: 0);
        height: 200%;
        left: 0;
        top: 100%;
        overflow: hidden;
        will-change: transform;

        body.side-hide & {
          display: none;
        }
        .foreground {
          position: absolute;
          background: $header-color;
          content: "";
          width: 100%;
          height: 50%;
          top: 0%;
          right: 0;
          will-change: transform;
        }
      }
      .hamburger-wrap {
        display: none;
      }
      .logo-wrap {
        position: absolute;
        @include ib-grid(6, 34);
        @include prefix(1, 34);
        @include suffix(11, 34);
        max-width: 185px;
        top: 50%;
        transform: translateY(-50%);

        &.main {
          .state-header-slim & {
            display: none;
          }
        }
        &.slim {
          max-width: 50px;
          display: none;

          .state-header-slim & {
            display: inline-block;
          }
        }
      }
      & > .nav-wrap {
        position: absolute;
        @include ib-grid(19, 34);
        top: 30px;
        left: percentage(15 / 34);

        @include desktop-large-only {
          @include ib-grid(16, 34);
          top: 30px;
          left: percentage(18 / 34);
        }

        @include desktop-ipad-only {
          @include ib-grid(22, 34);
          top: 30px;
          left: percentage(12 / 34);
        }

        @include desktop-small-only {
          @include ib-grid(20, 34);
          top: 30px;
          left: percentage(14 / 34);
        }

        .state-header-slim & {
          top: 11px;
        }
        nav {
          a {
            display: inline-block;

            span {
              padding-bottom: 5px;

              .state-header-slim & {
                padding-bottom: 3px;
              }
            }
          }
        }
        .main-nav {
          margin-bottom: 38px;

          .state-header-slim & {
            margin-bottom: 11px;
          }
          // .state-header-collapsed & {
          //   margin-bottom: percentage(0.5/16);
          // }
          a {
            @include suffix(0.5, 16, $gutter: 0);

            &:last-child {
              margin-right: 0;
            }
          }
        }
        .sub-nav {
          a {
            margin-right: 12px;
          }
          nav {
            display: none;

            &.about {
              body.section-about & {
                display: block;
              }
            }
            &.education {
              body.section-education & {
                display: block;
              }
            }
            &.program {
              body.section-program & {
                display: block;
              }
            }
            &.collection {
              body.section-collection & {
                display: block;
              }
            }
          }
        }
      }
    }
  }
  @include mobile-only {
    .header-inner {
      display: none;
    }
  }
  .mobile-menu {
    background: $green-light;

    .section-exhibition-home &,
    .section-exhibition-detail & {
      background: $grey-light;
    }
    .state-menu-visible & {
      background: $white;
    }
    .nav-wrap {
      background: $green-light;
    }
    .title-wrap {
      @include untitled(12px);
    }
    .nav-wrap {
      @include untitled(15px);
      background: $green-medium;
      line-height: 1.53;

      h3 {
        @include untitled(24px);
        line-height: 1.53;
      }
    }
    @include desktop-only {
      display: none;
    }
    @include mobile-only {
      position: relative;

      .state-menu-visible & {
        height: 100vh;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
      }
      .overlay-subscribe-wrap {
        position: absolute;
        height: 100vh;
        top: 0;
        display: none;

        .state-mobile-subscribe-open & {
          display: block;
          z-index: 12;
        }
        .subscribe-inner {
          form {
            bottom: 125px;
          }
          .message {
            bottom: 102px;
          }
        }
      }
      .top-wrap {
        position: relative;
        padding: 35px 0 30px;

        .state-mobile-subscribe-open & {
          display: none;
        }
        .logo-wrap {
          @include ib-grid(8);
          @include prefix(3);

          body.section-exhibition-home & {
            display: none;
          }
        }
        .home-logo-wrap {
          @include ib-grid(17.7);
          @include prefix(3);
          display: none;

          body.section-exhibition-home & {
            display: inline-block;
          }
        }
        .title-wrap {
          position: absolute;
          bottom: 45px;
          left: 0;
          @include ib-grid(12);
          @include prefix(14);
          display: none;

          p {
            margin: 0;
          }
        }
        .hamburger-wrap {
          position: absolute;
          @include ib-grid(7, $gutter: 0);
          right: 0;
          top: 0;
          height: 100%;
          z-index: 10;
        }
        button.hamburger {
          position: absolute;
          @include ib-grid(7, 7, $gutter: 0);
          right: 0;
          top: 0;
        }
        .feat-exhibition {
          position: absolute;
          @include ib-grid(7, $gutter: 0);
          top: 0;
          right: 0;
          height: 100%;
          pointer-events: none;

          &:after {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: $featured-exhibition-overlay;
          }
        }
      }
      .nav-wrap {
        padding: 35px 0 400px;
        display: none;

        .state-menu-visible & {
          display: block;
        }
        .state-mobile-subscribe-open & {
          display: none;
        }
        .group {
          @include ib-grid(20);
          @include prefix(3);
          @include suffix(13);
          margin-bottom: 25px;

          &:last-child {
            margin-bottom: 0;
          }
          a {
            display: block;
          }
          h3,
          p {
            margin: 0;
          }
        }
      }
    }
  }
}

.header-spacer {
  @include desktop-only {
    padding-top: #{$header-height * 3};

    .state-header-slim & {
      padding-top: #{$header-slim-height * 3};
    }
    .header-spacer-small & {
      padding-top: #{$header-height * 1};
    }
    .header-spacer-small.state-header-slim & {
      padding-top: #{$header-slim-height * 1};
    }
  }
}

.cart-spacer {
  @include desktop-only {
    padding-top: 90px;
  }
}

footer#footer {
  background: $green-medium-light;
  color: $footer-text-color;

  body.p404 & {
    display: none !important;
  }
  .footer-inner {
    background: $green-medium-light;
  }
  .text {
    @include untitled(11px);
    line-height: 1.3076923077;

    a {
      color: inherit;
    }
  }
  .subscribe-link {
    @include stealth-link;
    cursor: pointer;
  }
  @include desktop-only {
    position: relative;
    @include grid-container;

    .state-home-open & {
      display: none;
    }
    p {
      margin: 0 0 0.5em;
    }
    .overlay-subscribe-wrap {
      z-index: 12;
    }
    .footer-inner {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      padding: 35px 0 80px;
      z-index: 12;

      @include desktop-large-only {
        padding-bottom: 55px;
      }
      @include desktop-xl-only {
        padding-bottom: 55px;
      }
      .contact-wrap {
        @include ib-grid(13, $gutter: 0);
        @include prefix(1, $gutter: 0);

        @include desktop-ipad-only {
          @include ib-grid(16, $gutter: 0);
          @include prefix(1, $gutter: 0);

          .text {
            &:nth-child(1) {
              @include ib-grid(7.5, 16, $gutter: 0);
            }
            &:nth-child(2) {
              @include ib-grid(7.5, 16, $gutter: 0);
              @include prefix(1, 13, $gutter: 0);
            }
          }
        }
        @include desktop-small-only {
          @include ib-grid(16, $gutter: 0);
          @include prefix(1, $gutter: 0);

          .text {
            &:nth-child(1) {
              @include ib-grid(7, 16, $gutter: 0);
            }
            &:nth-child(2) {
              @include ib-grid(7, 16, $gutter: 0);
              @include prefix(1.5, 13, $gutter: 0);
            }
          }
        }
        .text {
          &:nth-child(1) {
            @include ib-grid(6, 13, $gutter: 0);
          }
          &:nth-child(2) {
            @include ib-grid(5.5, 13, $gutter: 0);
            @include prefix(1, 13, $gutter: 0);
          }
        }
      }
      .social-wrap {
        @include ib-grid(6, $gutter: 0);
        @include prefix(1, $gutter: 0);

        @include desktop-ipad-only {
          @include ib-grid(5, $gutter: 0);
          @include prefix(1, $gutter: 0);
        }
        @include desktop-small-only {
          @include ib-grid(5, $gutter: 0);
          @include prefix(1, $gutter: 0);
        }
        .subscribe-wrap {
          margin-bottom: 28px;
        }
        & > .text {
          a {
            display: inline-block;
            margin-right: 10px;
          }
        }
      }
      .logo-list {
        @include ib-grid(9.75, $gutter: 0);
        @include prefix(3.25, $gutter: 0);

        @include desktop-ipad-only {
          @include prefix(1.25, $gutter: 0);
        }
        @include desktop-small-only {
          @include prefix(1.25, $gutter: 0);
        }
        @include desktop-large-only {
          max-width: 390px;
        }
        @include desktop-xl-only {
          max-width: 390px;
        }
        .sponsor-wrap {
          @include ib-grid(5.5, 9.75, $gutter: 0);
          @include prefix(1.5, 9.75, $gutter: 0);
          vertical-align: middle;
        }
        .logo-wrap {
          @include ib-grid(2.75, 9.75, $gutter: 0);
          vertical-align: middle;
        }
      }
    }
  }
  @include mobile-only {
    position: relative;

    .footer-inner {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      padding: 15px 0 35px;
      z-index: 2;

      .logo-wrap {
        position: absolute;
        @include ib-grid(7.5);
        @include suffix(2, $gutter: 0);
        padding-top: percentage(2 / $total-cols);
        top: 0;
        right: 0;
      }
      .contact-wrap {
        @include ib-grid(23);
        @include prefix(3);
        margin-top: percentage(7.5 / $total-cols);
        margin-bottom: 25px;
      }
      .social-wrap {
        @include ib-grid(31);
        @include prefix(3);
        margin-bottom: 20px;

        .subscribe-wrap {
          @include ib-grid(15, 31);
        }
        & > .text {
          @include ib-grid(15, 31);
          @include prefix(1, 31);

          a {
            display: inline-block;
            margin-right: 10px;
          }
        }
      }
      .sponsor-wrap {
        @include ib-grid(10.5);
        @include prefix(3);
      }
    }
  }
}

.overlay-subscribe-wrap {
  background: $grey-light;

  p.message {
    @include untitled(18px);

    &.error {
      color: $red;
    }
  }
  form.styled {
    h3 {
      @include untitled(42px, $mobile-font-max: 32px);
      line-height: 1.0070600101;
      font-weight: 300;

      p {
        margin: 0;
      }
    }
    .form-field {
      border-bottom: 0;

      label,
      input {
        @include untitled(30px, $mobile-font-max: 24px);
      }
      input {
        background: $white;
      }
    }
  }

  @include grid-container;

  @include desktop-only {
    position: absolute;
    width: 100vw;
    height: 460px;
    left: 0;
    top: 0;
    z-index: 1;
    will-change: transform;
    transform: translateY(0%);
    transition: transform 0.25s;

    .state-scrollbar-active & {
      width: calc(100vw - 15px);
    }
    .state-footer-subscribe-open & {
      transform: translateY(-100%);
    }
    .subscribe-inner {
      button.hamburger {
        position: absolute;
        right: 0;
        top: 0;
        @include suffix(1);
        margin-top: calc((100% / #{$total-cols}) * 1 - 10px);
        padding: 0;

        .hamburger-inner,
        .hamburger-inner:after,
        .hamburger-inner:before {
          height: 1px;
        }
      }
      form {
        position: absolute;
        @include ib-grid(34, $gutter: 0);
        @include prefix(1, $gutter: 0);
        left: 0;
        bottom: 0;
        padding-bottom: percentage(1 / $total-cols);

        h3 {
          margin: 0 0 32px;
        }
        .form-field {
          @include ib-grid(26.5, 34, $gutter: 0);

          &.state-active {
            label {
              display: none;
            }
          }
          label {
            left: percentage(1 / 26.5);
          }
          input {
            padding: 40px percentage(1 / 26.5);
          }
        }
      }
      & > p.message {
        position: absolute;
        @include ib-grid(34, $gutter: 0);
        @include prefix(1, $gutter: 0);
        left: 0;
        bottom: 0;
        padding-bottom: percentage(1 / $total-cols);
        margin-bottom: 0 !important;
        padding-left: percentage(1 / $total-cols);
      }
      &.error > p.message {
        bottom: 130px;
      }
    }
  }
  @include mobile-only {
    position: absolute;
    width: 100%;
    height: 460px;
    left: 0;
    top: 0;
    z-index: 1;
    will-change: transform;
    transform: translateY(0%);
    transition: transform 0.25s;

    .state-footer-subscribe-open & {
      transform: translateY(-100%);
    }
    .subscribe-inner {
      button.hamburger {
        position: absolute;
        right: 0;
        top: 0;
        @include suffix(3);
        margin-top: percentage(3 / $total-cols);
        padding: 0;

        .hamburger-inner,
        .hamburger-inner:after,
        .hamburger-inner:before {
          height: 1px;
        }
      }
      form {
        position: absolute;
        @include ib-grid(30, $gutter: 0);
        @include prefix(3, $gutter: 0);
        left: 0;
        bottom: 0;
        padding-bottom: percentage(3 / $total-cols);

        h3 {
          margin: 0 0 25px;
        }
        .form-field {
          &.state-active {
            label {
              display: none;
            }
          }
          label {
            left: percentage(1 / 30);
          }
          input {
            padding: 30px percentage(1 / 30);
          }
        }
      }
      & > p.message {
        position: absolute;
        @include ib-grid(30, $gutter: 0);
        @include prefix(3, $gutter: 0);
        left: 0;
        bottom: 0;
      }
    }
  }
}
