@mixin hover {
  &:hover {
    .no-touchevents &,
    .no-js & {
      @content;
    }
  }

  &.hover {
    @content;
  }
}
