// this caused an infuriating "Invalid mapping" error on webpack build
// $inputs: 'input[type="text"], input[type="email"], input[type="number"], ' +
//          'input[type="search"], input[type="password"], input[type="date"], ' +
//          'input[type="url"]';

input[type="number"],
input[type="text"],
input[type="password"],
input[type="file"],
input[type="email"],
input[type="url"],
input[type="search"],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  outline: none;

  // indendation required to placate webpack/scss-loader
  &::placeholder {
    // color: inherit;
    // color: currentColor;
    opacity: 1;
  }
  &:focus {
    outline: none;

    &::placeholder {
      opacity: 0;
      transition: 200ms opacity;
    }
  }
}

input[type="number"] {
  @include mobile-only {
    -webkit-appearance: initial;
    -moz-appearance: initial;
  }
}

form.styled,
.styled-form {
  &.autosubmit {
    .js & {
      input[type="submit"] {
        display: none;
      }
    }
  }

  .form-field {
    position: relative;
    border-bottom: 1px solid $black;

    &.state-active {
      label {
        @include untitled(10px);
        transform: translate3d(calc(-100% - 25px), 0.8em, 0);

        @include mobile-only {
          display: none;
        }
      }
    }
    &.textarea {
      border-bottom: 0;
      margin-top: 25px;
      margin-bottom: 15px;

      &.state-active {
        label {
          @include untitled(10px);
          transform: translateX(calc(-100% - 25px));

          @include desktop-only {
            max-width: 150px;
          }

          @include mobile-only {
            display: none;
          }
        }
      }
      label {
        @include untitled(14px);
        top: 0.5em;
        left: 0.5em;
        transform: none;
      }
    }
    &.fileinput {
      .errorlist {
        color: $red;
        padding: 0;
        margin: 5px 0 0;

        &:first-child {
          display: none;
        }
        li {
          list-style: none;
        }
      }
      .help {
        @include untitled(12px);
        margin-top: 4px;

        &:last-child {
          display: none;
        }
      }

      .input-wrap {
        position: relative;
        @include untitled(12px);

        .no-touchevents &:hover {
          opacity: 0.6;
        }

        [type="file"] {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          height: 100%;
          width: 100%;
          opacity: 0.01;
          z-index: 1;
          border: 0;
          background: none;
          cursor: pointer;
        }

        span {
          display: block;
          padding: 15px 20px;
          border: 1px solid $black;
          @include untitled(14px);
          text-align: center;
        }
      }
    }
    &.fileinput,
    &.checkbox,
    &.radio-select {
      border-bottom: 0;

      label {
        position: static;
        top: auto;
        left: auto;
        @include untitled(14px);
        transform: none;
        pointer-events: all;
        user-select: none;
      }
    }
    &.checkbox,
    &.radio-select .custom-radio-select {
      input {
        position: absolute;
        opacity: 0;

        &:checked {
          & + label {
            position: relative;

            &:before {
              background-color: $black;
            }
          }
        }
      }
      label {
        cursor: pointer;

        .no-touchevents &:hover,
        &.is-active {
          &:before {
            background-color: $black;
          }
        }
        &:before {
          content: "";
          margin-right: 8px;
          display: inline-block;
          vertical-align: text-top;
          width: 16px;
          height: 16px;
          border: 1px solid $black;
        }
      }
    }
    &.checkbox {
      input {
      }
    }
    &.radio-select {
      padding-top: 22px;

      & > label {
        @include untitled(18px, $mobile-font-max: 15px);
      }
      &.state-active {
        label {
          @include mobile-only {
            display: block;
          }
        }
      }
    }
  }

  .errorlist {
    @include untitled(14px);
  }

  label {
    position: absolute;
    left: 0;
    top: 0.5em;
    // transform: translate3d(0%, -50%, 0);
    @include untitled(18px, $mobile-font-max: 15px);
    cursor: pointer;
    pointer-events: none;
  }

  input[type="text"],
  input[type="url"],
  input[type="search"],
  input[type="password"],
  input[type="number"],
  input[type="email"],
  .select-mask {
    @include untitled(18px, $mobile-font-max: 15px);
    display: block;
    background: inherit;
    border: 0;
    padding: 0.5em 0;
    width: 100%;
  }

  textarea {
    display: block;
    @include untitled(14px);
    border: 1px solid $black;
    background: none;
    width: 100%;
    padding: 0.5em;
    resize: none;
  }

  .select-mask {
    position: relative;
    padding-right: 30px;
    cursor: pointer;

    &::after {
      content: "â¼";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin-right: 11px;
      margin-top: 15px;
      cursor: pointer;
      font-size: 8px;
    }

    select {
      cursor: pointer;
    }
  }

  .help {
    margin: 0;
    padding-bottom: 0.5em;
  }

  input[type="submit"] {
    @include untitled(14px);
    display: block;
    width: 100%;
    max-width: 350px;
    text-align: center;
    background: transparent;
    color: inherit;
    border: 0;
    padding: 1em;
  }
}

@mixin shop-button-style {
  background: $black-true;
  color: $white;
  border: 0;
  border-radius: 0;
  text-align: center;
  display: inline-block;
  width: 100%;
  padding: 15px;
  @include untitled(13px);
  font-weight: 500;
  outline: none;

  .no-touchevents &:hover {
    opacity: 0.8;
  }
}

@mixin cart_add_form {
  .form-field {
    input[type="number"],
    select,
    .select-mask {
      width: 100%;
    }
    input[type="number"] {
      @include untitled(15px);
      line-height: 1.3333333333;
      padding: 8px;
      background: $grey-medium;
      border: 0;
      text-align: center;
    }
    &.qty {
      label {
        @include untitled(15px);
        line-height: 1.3333333333;
      }
      .input-wrap {
        box-sizing: border-box;
      }
    }
  }
}

.datepicker-top-left,
.datepicker-top-right {
  border-top-color: $green-home !important;
}

.datepicker-top-left::before,
.datepicker-top-right::before {
  border-bottom-color: $green-home !important;
}

.datepicker-panel > ul > li.picked,
.datepicker-panel > ul > li.picked:hover {
  color: $green-home !important;
}

.datepicker-panel > ul > li:hover {
  background-color: $border-color !important;
}

.datepicker-panel > ul > li.highlighted {
  background-color: $border-color !important;
}
