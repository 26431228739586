$page-min-height: 850px;
$page-min-height-mobile: 630px;

.goulburn-collection,
.goulburn-award,
.goulburn-contact,
.goulburn-terms {
  .std-page-wrap {
    @include desktop-only {
      .detail-wrap {
        min-height: $page-min-height;
        padding-bottom: calc((100% / 34) * 2 + 45px);
      }
    }
    @include mobile-only {
      .detail-wrap {
        padding-bottom: 120px;

        & > .text-wrap {
          min-height: $page-min-height-mobile;
        }
      }
    }
  }
}

.goulburn-education,
.goulburn-education-workshops,
.goulburn-education-outreach,
.goulburn-education-resources,
.goulburn-education-schools,
.goulburn-support,
.goulburn-visit,
.goulburn-award,
.goulburn-collection,
.goulburn-terms,
.goulburn-contact {
  .std-page-wrap {
    @include desktop-only {
      height: calc(100% - #{$header-height * 3});

      .state-header-slim & {
        height: calc(100% - #{$header-slim-height * 3});
      }
      .header-spacer-small & {
        height: calc(100% - #{$header-height * 1});
      }
      .header-spacer-small.state-header-slim & {
        height: calc(100% - #{$header-slim-height * 1});
      }
    }
  }
}

.goulburn-education,
.goulburn-education-workshops,
.goulburn-education-outreach,
.goulburn-education-resources,
.goulburn-education-schools,
.goulburn-support,
.goulburn-visit {
  .std-page-wrap {
    @include desktop-only {
      .accordion-wrap {
        padding-bottom: calc((100% / 34) * 2 + 170px);
      }
    }
  }
}

.goulburn-about {
  .std-page-wrap {
    @include desktop-only {
      .people-wrap {
        padding-bottom: calc((100% / 34) * 2 + 45px);
      }
    }
  }
}

.goulburn-award {
  .std-page-wrap {
    .form-wrap {
      padding-bottom: 12em;

      form {
        &.has-max-links {
          .link-add {
            display: none !important;
          }
        }
      }
      .error-message {
        color: $red;
      }
      .errorlist {
        color: $red;
        padding: 0;
        margin: 12px 0 0;

        li {
          list-style: none;
        }
      }
      .custom-radio-select {
        ul {
          padding: 0;
          list-style: none;

          li {
            @include desktop-only {
              @include ib-grid(7, 16, $gutter: 0);
              @include prefix(1, 16, $gutter: 0);

              &:first-child {
                margin-left: 0;
              }
            }
            @include mobile-only {
              margin-bottom: 12px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
      .form-field {
        &.required {
          label {
            &:after {
              content: "*";
              margin-left: 3px;
            }
          }
        }
        &.fileinput {
          @include desktop-only {
            margin-bottom: 30px;
          }
          @include mobile-only {
            margin-bottom: 25px;
          }
          & > label {
            @include untitled(18px);
          }

          .file-name {
            margin-top: 1em;
            @include desktop-only {
              display: inline-block;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 100%;
              margin-left: 2ch;
              margin-top: 0;
            }
          }
        }

        &.work_sell_at_gallery {
          padding: 0.5em 0;
          & > label {
            @include untitled(18px);
          }
          &:not(.is-checked) {
            + .work_price {
              display: none;
            }
          }

          &.required {
            li > label {
              &:after {
                content: "";
              }
            }
          }
        }

        &.checkbox {
          margin-bottom: 1em;
        }

        &.where_did_you_hear_about_us {
          border-top: 1px solid $black;
          padding-top: 1em;
          > label {
            @include untitled(18px);
            transform: none;
            position: unset;
          }
          .input-wrap {
            ul {
              list-style: none;
              padding: 0;
              // ::marker {
              //   content: none;
              // }
            }
            li {
              margin-bottom: 0.25em;
            }
            label {
              @include untitled(14px);
              position: unset;
              pointer-events: all;
              cursor: pointer;

              &.is-active {
                &:before {
                  background-color: $black;
                }
              }
              &:before {
                content: "";
                margin-right: 8px;
                display: inline-block;
                vertical-align: text-top;
                width: 16px;
                height: 16px;
                border: 1px solid $black;
              }

              &:after {
                content: "";
              }
            }
            input {
              display: none;
            }
          }
        }
      }
      .link-add {
        @include untitled(13px);
        cursor: pointer;
        visibility: hidden;

        .state-initialised & {
          transition: opacity 0.25s ease;
        }
        .no-touchevents &:hover {
          opacity: 0.65;
        }
        &.is-visible {
          visibility: inherit;
        }
      }
      .checkout-buttons {
        input[type="submit"] {
          @include shop-button-style;
        }
      }
    }
    .link-template-wrap {
      display: none;
    }

    @include desktop-only {
      .detail-wrap {
        .form-wrap {
          @include ib-grid(16, 34);
          @include prefix(7, 34);

          .form-field {
            &.fileinput {
              margin-bottom: 30px;

              .input-wrap {
                @include ib-grid(6, 16, $gutter: 0);
                margin-top: 15px;
              }
            }
            &.gst_status {
              margin-bottom: 30px;
            }
          }
          .link-wrap {
            margin-top: 20px;
          }
          .link-add {
            display: inline-block;
            margin-top: 12px;
            margin-bottom: 42px;
            margin-left: -12px;
          }
          .checkout-buttons {
            margin-top: 55px;
          }
        }
      }
    }
    @include mobile-only {
      .detail-wrap {
        .form-wrap {
          @include ib-grid(30);
          @include prefix(3);

          .form-field {
            &.fileinput {
              margin-bottom: 30px;

              .input-wrap {
                // @include ib-grid(6, 16, $gutter: 0);
                margin-top: 15px;
              }
            }
            &.gst_status {
              margin-bottom: 30px;
            }
            &.work_sell_at_gallery,
            &.terms_agreed,
            &.terms_original_and_completed_recently,
            &.terms_agree_to_digitalization,
            &.signup_for_mailing_list,
            &.where_did_you_hear_about_us {
              > label {
                @include untitled(16px);
                display: block;
              }
              .input-wrap label {
                display: block;

                transform: none;
              }
            }
          }
          .link-wrap {
            margin-top: 20px;
          }
          .link-add {
            display: inline-block;
            margin-top: 12px;
            margin-bottom: 42px;
          }
          .checkout-buttons {
            margin-top: 35px;
          }
        }
      }
    }
  }
}

.goulburn-public-art {
  .alt-page-wrap {
    @include desktop-only {
      // min-height: $page-min-height;
    }
    .detail-wrap {
      background: $grey-light;

      h2 {
        @include untitled(30px, $mobile-font-max: 28px);
        font-weight: 500;
        line-height: 1.3333333333;
      }
      .text-wrap {
        .intro-wrap,
        .public-art-wrap > .text {
          @include untitled(18px, $mobile-font-max: 14px);
          line-height: 1.2222222222;
        }
        .intro-wrap {
          .video-wrap {
            .caption {
              margin-top: 5px;
            }
          }
        }
        .public-art-wrap {
          border-top: 1px solid $border-color;

          .public-art-list {
            &.loading {
              .public-art-listing {
                .extra-wrap {
                  display: block;
                }
              }
            }
          }
          .public-art-listing {
            border-top: 1px solid $border-color;

            .no-touchevents &:hover,
            &.clicked {
              .title-wrap,
              .info-wrap {
                color: $green-medium;
              }
            }
            &:last-child {
              border-bottom: 1px solid $border-color;
            }
            h6,
            .number {
              @include untitled(12px);
              font-weight: 500;
              line-height: 1.1666666667;
              color: $green-medium;
            }
            h6 {
              &:after {
                content: "";
                background: url("../i/svg/arrow-down.svg") no-repeat;
                background-size: cover;
              }
            }
            .title-wrap,
            .info-wrap {
              cursor: pointer;
            }
            .text {
              @include untitled(11px);
              line-height: 1.2727272727;
            }
          }
        }
      }
      .image-wrap {
        .caption {
          @include untitled(7px);
          line-height: 2.2857142857;
          text-align: right;
        }
      }
      @include desktop-only {
        position: relative;
        @include ib-grid(34, $gutter: 0);

        .text-wrap {
          @include ib-grid(13, 34);
          @include prefix(7, 34);
          margin-bottom: 160px;
          padding-top: 40px;

          h2 {
            margin: 0 0 40px;
          }
          .intro-wrap {
            margin-bottom: 30px;

            .video-wrap {
              margin: 45px 0;
            }
          }
          .public-art-wrap {
            padding-top: 10px;

            & > .text {
              margin-bottom: 20px;
            }
            .public-art-listing {
              position: relative;
              padding: 15px 0;

              &.clicked {
                h6 {
                  &:after {
                    transform: translateY(-50%) rotateX(180deg);
                  }
                }
                .extra-wrap {
                  display: block;
                }
              }
              h6,
              p {
                margin: 0;
              }
              h6 {
                position: relative;

                &:after {
                  position: absolute;
                  display: block;
                  width: 14px;
                  height: 10px;
                  top: 50%;
                  right: 5px;
                  transform: translateY(-50%);
                }
              }
              .number {
                position: absolute;
                @include ib-grid(1, 13);
                top: 15px;
                left: 0;
              }
              .title-wrap {
                @include ib-grid(12, 13);
                @include prefix(1, 13);
                padding-bottom: 5px;
              }
              .info-wrap,
              .extra-wrap {
                @include ib-grid(12, 13);
                @include prefix(1, 13);
              }
              .extra-wrap {
                display: none;
                margin-top: 30px;
                margin-bottom: 15px;

                .image-wrap {
                  margin-bottom: 32px;
                }
                .text {
                  p {
                    margin-bottom: 1em;

                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
          }
        }
        .media-wrap {
          @include ib-grid(13, 34, $gutter: 0);
          @include prefix(1, 34, $gutter: 0);
          margin-bottom: 160px;

          .image-wrap {
            position: relative;

            .caption {
              position: absolute;
              margin: 0;
              width: 75%;
              left: calc(100% + 5px);
              bottom: 0;
              transform: rotate(90deg) translateX(-100%);
              transform-origin: bottom left;
            }
          }
          .map {
            &.fixed {
              position: fixed;
              @include ib-grid(13, $gutter: 0);
              bottom: auto;
              top: $header-height;
              margin-bottom: 0;
              transition: transform 0.25s;

              .state-header-slim & {
                top: $header-slim-height;
              }
              .state-header-collapsed & {
                transform: translateY(-#{$header-height});
              }
              .state-header-collapsed.state-header-slim & {
                transform: translateY(-#{$header-slim-height});
              }
            }
            &.bottom {
              position: absolute;
              @include ib-grid(13, 34, $gutter: 0);
              top: auto;
              bottom: 500px;
              margin-bottom: 0;
              transition: none;

              .state-header-slim & {
                top: auto;
              }
              .state-header-collapsed & {
                transform: none;
              }
              .state-header-collapsed.state-header-slim & {
                transform: none;
              }
            }
          }
        }
      }
      @include mobile-only {
        margin-bottom: 120px;
        padding-top: percentage(33 / $total-cols);

        .text-wrap {
          @include ib-grid(30);
          @include prefix(3);
          margin-bottom: 40px;
          padding-top: 40px;

          h2 {
            margin: 0 0 30px;
          }
          .intro-wrap {
            margin-bottom: 30px;

            .video-wrap {
              margin-top: 25px;
            }
          }
          .public-art-wrap {
            padding-top: 10px;

            & > .text {
              margin-bottom: 20px;
            }
            .public-art-listing {
              position: relative;
              padding: 10px 0 15px;

              &.clicked {
                h6 {
                  &:after {
                    transform: translateY(-50%) rotateX(180deg);
                  }
                }
                .extra-wrap {
                  display: block;
                }
              }
              h6,
              p {
                margin: 0;
              }
              h6 {
                position: relative;
                padding-right: 25px;

                &:after {
                  position: absolute;
                  display: block;
                  width: 14px;
                  height: 10px;
                  top: 50%;
                  right: 5px;
                  transform: translateY(-50%);
                }
              }
              .number {
                position: absolute;
                display: block;
                left: percentage(-2 / $total-cols);
                top: 10px;
              }
              .title-wrap {
                margin-bottom: 5px;
              }
              .extra-wrap {
                display: none;
                margin-top: 20px;

                .text {
                  margin-top: 10px;

                  p {
                    margin-bottom: 1em;

                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
          }
        }
        .media-wrap {
          .image-wrap {
            position: absolute;
            @include ib-grid(33, $gutter: 0);
            @include prefix(3, $gutter: 0);
            top: 0;
            left: 0;

            .caption {
              display: none;
            }
          }
        }
      }
    }
  }
}

.goulburn-support {
  .detail-wrap {
    .join-membership {
      a {
        @include untitled(13px);
        font-weight: 500;
        line-height: 1.3846153846;
        color: $green-medium;
      }
    }
    @include desktop-only {
      .join-membership {
        padding-top: 15px;

        h3 {
          margin: 0 0 3px;
        }
      }
    }
    @include mobile-only {
      .join-membership {
        padding-top: 15px;

        h3 {
          margin: 0 0 3px;
        }
      }
    }
  }
}

.goulburn-visit {
  .std-page-wrap {
    .detail-wrap {
      .text-wrap {
        & > .text {
          @include untitled(13px);
          line-height: 1.3846153846;
          border-top: 1px solid $border-color;
        }
      }
    }
    @include desktop-only {
      .detail-wrap {
        .text-wrap {
          margin-bottom: 30px;

          & > .text {
            padding-top: 25px;
          }
        }
      }
    }
    @include mobile-only {
      .detail-wrap {
        .text-wrap {
          margin-bottom: 30px;

          & > .text {
            padding-top: 25px;
          }
        }
      }
    }
  }
}

.std-page-wrap,
.alt-page-wrap {
  background: $white;
  color: $black-true;

  @include desktop-only {
    position: relative;
    min-height: $page-min-height;

    &:after {
      position: absolute;
      content: "";
      background: $page-bottom-bar;
      @include ib-grid(24, $gutter: 0);
      @include suffix(12, $gutter: 0);
      left: 0;
      bottom: 0;
      padding-top: percentage(2 / $total-cols);
    }
    .page-filler {
      background: $grey-light;
      @include ib-grid(34, $gutter: 0);
      @include suffix(2, $gutter: 0);
      height: 0;
      display: none;
    }
  }
  @include mobile-only {
    .detail-wrap {
      position: relative;

      &.has-mobile-image {
        padding-top: percentage(33 / $total-cols);

        &:before {
          position: absolute;
          @include ib-grid(33, $gutter: 0);
          top: 0;
          left: 0;
          padding-top: percentage(33 / $total-cols);
          background: $green-dark;
          content: "";
        }
        & > .image-wrap,
        & > .media-wrap > .image-wrap {
          position: absolute;
          @include ib-grid(33, $gutter: 0);
          @include prefix(3, $gutter: 0);
          top: 0;
          left: 0;

          .caption {
            display: none;
          }
        }
      }
    }
  }
}

.std-page-wrap {
  @include mobile-only {
    background: $green-medium;
  }
  .detail-wrap {
    background: $grey-light;

    @include mobile-only {
      background: $white;
    }
    h2 {
      @include untitled(30px, $mobile-font-max: 28px);
      font-weight: 500;
      line-height: 1.3333333333;
    }
    .text-wrap {
      a {
        color: $link-color;
      }
      .intro-wrap {
        @include untitled(18px);
        line-height: 1.2222222222;
      }
    }
    .image-wrap {
      .caption {
        @include untitled(7px);
        line-height: 2.2857142857;
        text-align: right;
      }
    }
    @include desktop-only {
      @include ib-grid(34, $gutter: 0);

      .text-wrap {
        @include ib-grid(13, 34);
        @include prefix(7, 34);
        margin-bottom: 60px;
        padding-top: 40px;

        h2 {
          margin: 0 0 30px;
        }
        .intro-wrap {
          margin-bottom: 30px;
        }
      }
      .image-wrap {
        position: relative;
        @include ib-grid(13, 34, $gutter: 0);
        @include prefix(1, 34, $gutter: 0);
        margin-bottom: 60px;

        .caption {
          position: absolute;
          margin: 0;
          width: 75%;
          left: calc(100% + 5px);
          bottom: 0;
          transform: rotate(90deg) translateX(-100%);
          transform-origin: bottom left;
        }
      }
    }
    @include mobile-only {
      .text-wrap {
        @include ib-grid(30);
        @include prefix(3);
        @include suffix(3);
        margin-bottom: 20px;
        padding-top: 40px;

        h2 {
          margin: 0 0 30px;
        }
        .intro-wrap {
          margin-bottom: 30px;
        }
      }
    }
  }
}

.people-wrap {
  background: $grey-light-people;

  h2 {
    @include untitled(30px);
    font-weight: 500;
    line-height: 1.3333333333;
  }
  h4 {
    @include untitled(22px);
    font-weight: 500;
    line-height: 1.2;
  }
  .person-listing {
    @include untitled(12px);
    line-height: 1.5;

    @include mobile-only {
      @include untitled(14px);
      line-height: 1.5;
    }
    .contact-wrap {
      color: $link-color;
    }
  }
  @include desktop-only {
    @include ib-grid(34, $gutter: 0);
    padding-top: 40px;

    h2 {
      @include ib-grid(23, 34);
      @include prefix(7, 34);
      @include suffix(6, 34);
      margin-bottom: 15px;
    }
    h4 {
      margin: 0;
    }
    .people-list {
      @include ib-grid(25, 34);
      @include prefix(7, 34);
      @include suffix(4, 34);

      .person-listing {
        margin-bottom: 65px;

        .image-wrap {
          @include ib-grid(9, 25);

          .image {
            margin-bottom: 10px;
          }
          .contact-wrap {
            p {
              margin: 0;
            }
          }
        }
        .person-text-wrap {
          @include ib-grid(15, 25);
          @include prefix(1, 25);

          .title-wrap {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  @include mobile-only {
    padding: 40px 0 120px;
    h2,
    .people-list {
      @include ib-grid(30);
      @include prefix(3);
      @include suffix(3);
    }
    h2 {
      margin-top: 0;
      margin-bottom: 12px;
    }
    h4 {
      margin: 0;
    }
    .people-list {
      .person-listing {
        margin-bottom: 60px;

        &:last-child {
          margin-bottom: 0;
        }
        .image-wrap {
          margin-bottom: 10px;
        }
        .person-text-wrap {
          .title-wrap {
            margin-bottom: 15px;
          }
          & > .text {
            :last-child {
              margin-bottom: 1em;
            }
          }
          .contact-wrap {
            p {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

article.p404 {
  .text-wrap {
    .text {
      @include untitled(12px);
      font-weight: 400;
    }
  }
  @include desktop-only {
    .text-wrap {
      position: fixed;
      @include ib-grid(34);
      left: 0;
      bottom: 60px;
      @include prefix(1);

      .image-wrap {
        @include ib-grid(3, 34, $gutter: 0);
        width: 100px;
        margin-bottom: 20px;
      }
    }
  }
  @include mobile-only {
    min-height: auto !important;

    .text-wrap {
      position: fixed;
      @include ib-grid(32);
      left: 0;
      bottom: 60px;
      @include prefix(2);

      .image-wrap {
        @include ib-grid(3, 34, $gutter: 0);
        width: 100px;
        margin-bottom: 20px;
      }
    }
  }
}
