.product-list-wrap,
.product-wrap {
  @include desktop-only {
    position: relative;

    .accordion-wrap {
      margin-bottom: 90px;
    }
    &:after {
      position: absolute;
      content: "";
      background: $page-bottom-bar;
      @include ib-grid(24, $gutter: 0);
      @include suffix(12, $gutter: 0);
      left: 0;
      bottom: 0;
      padding-top: percentage(2 / $total-cols);
    }
  }
}

.product-list-wrap {
  background: $grey-light;

  @include desktop-only {
    @include ib-grid(34, $gutter: 0);
  }
  .product-list {
    .product-listing {
      .image {
        &.blank {
          img {
            background: $grey-dark;
          }
        }
        img {
          opacity: 1;
          transition: opacity 0.25s;

          &:hover {
            opacity: 0.9;
          }
        }
      }
      .title-wrap {
        @include untitled(13.5px);
        line-height: 1.3333333333;
      }
    }
    @include desktop-only {
      margin-bottom: 20px;
      padding-top: calc((100% / 34) * 2 + 48px);

      .next {
        display: none;
      }
      .product-listing {
        @include ib-grid(15.5, 34, $gutter: 0);
        @include prefix(2, 34, $gutter: 0);
        margin-bottom: 45px;

        &:nth-child(2n + 2) {
          @include prefix(1, 34, $gutter: 0);
        }
        p {
          margin: 0;
        }
        .image-wrap {
          margin-bottom: 15px;

          .image {
            &.blank {
              padding-top: 75.4071661238%;
            }
          }
        }
      }
    }
    @include mobile-only {
      padding-top: percentage(2 / $total-cols);
      margin-bottom: 40px;

      .next {
        display: none;
      }
      .product-listing {
        @include ib-grid(30, $gutter: 0);
        @include prefix(3);
        @include suffix(3);
        margin-bottom: 50px;

        &:last-child {
          margin-bottom: 0;
        }
        p {
          margin: 0;
        }
        .image-wrap {
          margin-bottom: 12px;

          .image {
            &.blank {
              padding-top: 75.4071661238%;
            }
          }
        }
      }
    }
  }
  .accordion-wrap {
    @include desktop-only {
      .accordion-inner {
        &.loading {
          .section-list {
            .section-listing {
              .text {
                display: block;
              }
            }
          }
        }
      }
      .section-list {
        @include ib-grid(33, 34);
        @include prefix(2, 34);

        .section-listing {
          &.active {
            .text {
              display: block;
            }
          }
          .text {
            @include ib-grid(17.5, 33);
            display: none;
          }
        }
      }
    }
  }
}

.product-wrap {
  @include desktop-only {
    min-height: 850px;
  }
  .carousel-wrap {
    @include carousel-controls;

    .carousel-item {
      &.blank {
        background: $grey-dark;
      }
    }
    @include desktop-only {
      position: relative;
      width: 100%;

      .carousel-item {
        &.blank {
          padding-top: percentage(5 / $total-cols);
        }
      }
    }
    @include mobile-only {
      position: relative;

      .carousel {
        .flickity-page-dots {
          text-align: right;
          padding: 0 3px;
          top: calc(100% + 5px);
          bottom: auto;
        }
      }
      .carousel-controls {
        top: 100%;
        bottom: auto;

        .button {
          top: 0;
          bottom: auto;
        }
      }
    }
  }
  .product-detail-wrap {
    @include desktop-only {
      display: inline-flex;
      width: 100%;
    }
    .product-images-wrap {
      background: $header-color;

      .image-wrap {
        cursor: pointer;
      }
      @include desktop-only {
        @include ib-grid(18, $gutter: 0);
        padding: percentage(0.75 / $total-cols) 0;

        .image-wrap {
          @include ib-grid(5, 18);
          @include prefix(0.75, 18);
          margin-bottom: percentage(0.75/18);
        }
      }
      @include mobile-only {
        display: none;
      }
    }
    .detail-wrap {
      background: $grey-light;
      color: $black-true;

      @include mobile-only {
        background: none;
      }
      h3 {
        @include untitled(18px, $mobile-font-max: 16px);
        line-height: 1.2222222222;
      }
      .accordion-wrap {
        background: none;
      }
      .price-wrap {
        p {
          @include untitled(10px);
          line-height: 1.3;
        }
      }
      & > .text {
        @include mobile-only {
          @include untitled(12px);
        }
      }
      @include desktop-only {
        @include ib-grid(18, $gutter: 0);
        padding: percentage(1 / $total-cols) 0;

        h3 {
          margin: 0;
        }
        .title-wrap,
        .price-wrap,
        .variant-wrap,
        .add-to-cart-wrap,
        .accordion-wrap {
          @include ib-grid(14, 18);
          @include prefix(2, 18);
        }
        .title-wrap {
          margin-bottom: 20px;
        }
        .price-wrap {
          margin-bottom: 20px;

          h3 {
            margin-bottom: 15px;
          }
          p {
            margin: 0;
          }
        }
        .variant-wrap {
          margin-bottom: 20px;
        }
        .add-to-cart-wrap {
          margin-bottom: 60px;
        }
        .text {
          @include ib-grid(13, 18);
          @include prefix(2, 18);
          margin-bottom: 90px;
        }
        .accordion-wrap {
          .accordion-inner {
            &.loading {
              .section-list {
                .section-listing {
                  .text {
                    display: block;
                  }
                }
              }
            }
          }
          .section-list {
            width: 100%;
            margin: 0;

            .section-listing {
              &.active {
                .text {
                  display: block;
                }
              }
              .text {
                @include ib-grid(12, 14);
                display: none;
              }
            }
          }
        }
      }
      @include mobile-only {
        padding: 50px 0 0;

        h3 {
          margin: 0;
        }
        .title-wrap,
        .price-wrap,
        .variant-wrap,
        .text,
        .add-to-cart-wrap {
          @include ib-grid(30);
          @include prefix(3);
          @include suffix(3);
        }
        .title-wrap {
          margin-bottom: 18px;
        }
        .price-wrap {
          margin-bottom: 18px;

          h3 {
            margin-bottom: 13px;
          }
          p {
            margin: 0;
          }
        }
        .variant-wrap {
          margin-bottom: 18px;
        }
        .add-to-cart-wrap {
          margin-bottom: 50px;
        }
        .text {
          margin-bottom: 18px;
        }
      }
    }
  }
}

.add-to-cart-wrap {
  .add-form {
    @include cart_add_form;

    &.added {
      .checkout-buttons {
        position: relative;

        &:after {
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          text-align: center;
          background: $green-medium;
          content: "Added!";
          top: 0;
          left: 0;
          color: $white;
          padding: 15px;
          @include untitled(13px);
          font-weight: 500;
          outline: none;
        }
        input[type="submit"] {
          background: $green-medium;
        }
      }
    }

    input[type="submit"] {
      @include shop-button-style;
    }
    .form-field {
      input[type="number"] {
        text-align: center;
      }
    }
    .checkout-link {
      color: $green-medium;
      @include untitled(13px);
      font-weight: 500;

      a {
        @include stealth-link;
      }
    }
  }
  @include desktop-only {
    .add-form {
      position: relative;
      display: flex;

      &.added {
        .checkout-buttons {
          .checkout-link {
            display: block;
            position: absolute;
            width: 100%;
            top: calc(100% + 12px);
            left: 0;
          }
        }
      }
      .form-field {
        @include ib-grid(4.5, 14);
        // margin: 0 0 17px;

        &.qty {
          display: inline-flex;

          label {
            position: relative;
            @include ib-grid(2, 4.5);
            height: 100%;

            span {
              position: absolute;
              display: block;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          .input-wrap {
            @include ib-grid(2.5, 4.5);
            box-sizing: border-box;
            height: 100%;

            input {
              height: 100%;
            }
          }
        }
      }
      .checkout-buttons {
        @include ib-grid(6.5, 14);
      }
      .checkout-link {
        display: none;
      }
    }
  }
  @include mobile-only {
    .add-form {
      display: flex;

      .form-field {
        @include ib-grid(9.5, 30);
        // margin: 0 0 17px;

        &.qty {
          display: inline-flex;

          label {
            position: relative;
            @include ib-grid(4, 9.5);
            height: 100%;

            span {
              position: absolute;
              display: block;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          .input-wrap {
            @include ib-grid(5.5, 9.5);
            box-sizing: border-box;
            height: 100%;

            input {
              height: 100%;
            }
          }
        }
      }
      .checkout-buttons {
        @include ib-grid(14, 30);
        @include prefix(1, 30);
      }
      .checkout-link {
        display: none;
      }
    }
  }
}

.goulburn-shop,
.goulburn-shop-product {
  @include mobile-only {
    position: relative;
    padding-top: $cart-mobile-height;
  }
}
