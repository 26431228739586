@mixin restricted-vw-property($prop, $size, $min-width, $max-width) {
  @media (min-width: #{$min-width + 1px}) and
      (max-width: #{$max-width - 1px}) {
    #{$prop}: #{$size + "vw"};
  }
  @media (max-width: $min-width) {
    #{$prop}: $min-width * $size / 100;
  }
  @media (min-width: $max-width) {
    #{$prop}: $max-width * $size / 100;
  }
}

@mixin centred-children($inline: 0) {
  align-items: center;

  // children should be inline-block columns
  @if ($inline) {
    display: inline-flex;
  } @else {
    display: flex;
  }
}

@mixin centred-text($inline-block: false) {
  justify-content: center;
  align-items: center;
  @if ($inline-block) {
    display: inline-flex;
  } @else {
    display: flex;
  }
}

@mixin vw-fallback($prop, $val) {
  // set a vw/vh property with a preceding fallback based on a 960w viewport
  $fallback: 960px;

  #{$prop}: $val * $fallback / 100vw;
  #{$prop}: $val;
}

@mixin grayscale($val) {
  -webkit-filter: grayscale($val);
  filter: grayscale($val);
}

@mixin vert-gradient($from, $to) {
  background: $from; /* Old browsers */
  background: -moz-linear-gradient(top, $from 0%, $to 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, $from),
    color-stop(100%, $to)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    $from 0%,
    $to 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, $from 0%, $to 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, $from 0%, $to 100%); /* IE10+ */
  background: linear-gradient(to bottom, $from 0%, $to 100%); /* W3C */
}

@mixin hardware-accelerated {
  transform: translateZ(0);
}
