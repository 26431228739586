@import "../lib/type_mixins";

@mixin untitled(
  $font-size: null,
  $font-resize-cutoff: 960px,
  $mobile-font-max: null
) {
  font-family: "Untitled Sans", "Helvetica Neue", "Helvetica", sans-serif;

  @if $font-size {
    @include smart-font-size(
      $font-size,
      $lh-max: 1.36,
      $lh-lower-cutoff: 14px,
      $lh-min: 1,
      $lh-upper-cutoff: 35px,
      $ls-max: 0px,
      $ls-lower-cutoff: 10px,
      $ls-min: 0px,
      $ls-upper-cutoff: 130px,
      $font-resize-cutoff: $font-resize-cutoff,
      $mobile-font-max: $mobile-font-max
    );
  }
}

// these mixins define reusable groups of font-related styles, and should be
// used where possible to keep type styles consistent around the site
//
// mixin names should describe the appearance, not the usage, i.e.
// "national-large-heading" not "bulletin-article-heading"

$body-font-size: 14px;

@mixin body-type {
  @include untitled($body-font-size);
}

@mixin text-link($color: currentColor, $weight: 0.08em, $offset: 0.02em) {
  @include underline($color, $weight, $offset);

  .no-touchevents &:hover,
  &.current {
    @include no-underline;
  }
}

@mixin stealth-link($color: currentColor, $weight: 0.08em, $offset: 0.02em) {
  @include no-underline;

  .no-touchevents &:hover,
  &.current {
    @include underline($color, $weight, $offset);
  }
}
