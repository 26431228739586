.image {
  position: relative;

  & > a {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  &.lazy-load {
    &.loaded {
      img {
        display: block;
      }
    }
    img {
      display: none;
    }
  }
  img,
  .video-player {
    width: 100%;
    height: auto;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
  .image-height-setter {
    display: block;
  }
  .video-player {
    z-index: 2;
    height: 100%;
    pointer-events: none;
    .video-wrap {
      height: 100%;
      video {
        // assume user will upload video with right dimensions
        min-height: 100%;
        min-width: 100%;
        visibility: visible;
        opacity: 0;
        transition: none;
      }
    }
    &.videoplayer-playing,
    &.videoplayer-paused {
      .video-wrap video {
        opacity: 1;
        transition: 0s opacity ease 0.1s;
      }
    }
  }
}

// .mobile-carousel only becomes a carousel on mobile screen sizes
.carousel,
.mobile-carousel {
  .caption {
    padding: 12px 0 0;
    @include untitled(14px);

    p {
      margin: 0;
    }
  }
  .flickity-page-dots {
    padding: 0 10px;
    text-align: center;
    display: none;

    .dot {
      width: 12px;
      height: 12px;
      opacity: 1;
      margin: 0 4px;
      background: $grey-medium;
      border: 1px solid $grey-medium;
      color: $grey-medium;

      &.is-selected,
      .no-touchevents &:hover {
        background: $grey-light;
        border-color: $grey-light;
      }
      @include mobile-only {
        width: 8px;
        height: 8px;
      }
    }
    @include mobile-only {
      position: absolute;
      // display: block;
      bottom: 20px;
    }
  }
  .flickity-prev-next-button {
    background: none !important;
    top: calc(100% + 25px);
    @include untitled(20px);

    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
    &.previous:after {
      content: "â";
    }
    &.next:after {
      content: "â";
    }
    svg {
      display: none;
    }
  }
  &.flickity-enabled {
    img {
      // prevent it capturing drags
      pointer-events: none;
    }
  }
}

@mixin carousel-tabs($parent-cols: $total-cols) {
  .tab-wrap {
    .tab {
      @include ib-grid(4, $parent-cols);
      @include prefix(0.5, $parent-cols);
      color: $red;
      cursor: pointer;

      &:hover,
      &.is-selected {
        .caption-text {
          opacity: 0.275;
        }
      }
      .number {
        display: block;
        margin-bottom: 10px;
      }
    }
  }
}

@mixin carousel-controls($parent-cols: $total-cols) {
  .carousel-controls {
    @include desktop-only {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;

      &.slides-start {
        .button.previous {
          opacity: 0.5;
        }
      }
      &.slides-end {
        .button.next {
          opacity: 0.5;
        }
      }

      .button {
        position: absolute;
        width: 44px;
        top: 50%;
        padding: 10px;
        transform: translateY(-50%);
        pointer-events: all;
        cursor: pointer;
        opacity: 1;
        transition: opacity 0.25s;
        user-select: none;

        &:hover {
          opacity: 0.5;
        }
        &.previous {
          left: calc((100% / #{$parent-cols}) * 2 - 10px);
        }
        &.next {
          right: calc((100% / #{$parent-cols}) * 2 - 10px);
        }
      }
    }
    @include mobile-only {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      pointer-events: none;

      &.slides-start {
        .button.previous {
          opacity: 0.5;
        }
      }
      &.slides-end {
        .button.next {
          opacity: 0.5;
        }
      }

      .button {
        position: absolute;
        @include ib-grid(3, $gutter: 0);
        bottom: 10px;
        padding: 10px;
        pointer-events: all;
        cursor: pointer;
        opacity: 1;
        transition: opacity 0.25s;

        &.previous {
          left: 0;

          &:after {
            display: block;
            content: "â";
          }
        }
        &.next {
          right: 0;

          &:after {
            display: block;
            content: "â";
          }
        }
        .image {
          display: none;
        }
      }
    }
  }
}

@mixin carousel-enabled {
  &.flickity-enabled {
    &.is-fullscreen {
      padding-bottom: 0;

      .carousel-item {
        height: 100%;
      }
      .flickity-fullscreen-button {
        display: none;
      }
    }
    .carousel-item {
      display: block;
    }
    .flickity-fullscreen-button {
      display: none;
    }
  }
  .carousel-item {
    width: 100%;
    display: none;

    &:first-child {
      display: block;
    }
  }
}

.carousel {
  @include carousel-enabled;
}
.mobile-carousel {
  @include mobile-only {
    @include carousel-enabled;
  }
}

.carousel-wrap {
  .carousel-item {
    .video-player-wrap,
    .image-wrap {
      .text {
        @include untitled(24px);
        font-weight: 500;
        font-size: 1.9886363636vw;
        line-height: 1.2857142857;
        color: $white;

        @include mobile-only {
          @include untitled(20px);
          color: $grey-light;
        }

        p {
          margin-bottom: 0;
        }
      }
      .image,
      .poster {
        cursor: move;
        cursor: -webkit-grab;
        cursor: grab;
      }
    }
    @include desktop-only {
      .video-player-wrap,
      .image-wrap {
        position: relative;

        .text {
          position: absolute;
          left: percentage(1/25);
          bottom: percentage(2/16);
        }
      }
    }
  }
}

.page-header {
  @include desktop-only {
    position: fixed;
    top: $header-height;
    height: #{$header-height * 2};
    width: 100%;
    will-change: transform;
    z-index: -1;

    .state-header-slim & {
      top: $header-slim-height;
      height: #{$header-slim-height * 2};
    }
    body.side-hide & {
      position: absolute;
      z-index: 5;
    }
    &:before {
      display: block;
      position: absolute;
      background: $green-light;
      width: 100%;
      height: 100vh;
      content: "";
      bottom: 100%;
    }
    .feat-exhibition {
      position: absolute;
      @include ib-grid(2, $gutter: 0);
      height: 150%;
      top: -50%;
      right: 0;
      z-index: 15;

      &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: $featured-exhibition-overlay;
      }
    }
  }
}
.page-title-wrap {
  background: $green-light;

  .text-wrap {
    background: $green-medium;
    color: $white;

    .text {
      @include untitled(33px, $mobile-font-max: 30px);
      line-height: 1.1487878788;
    }
  }
  @include desktop-only {
    position: relative;
    height: 100%;
    overflow: hidden;

    .foreground {
      position: absolute;
      @include ib-grid(6, $gutter: 0);
      background: $green-dark;
      content: "";
      height: 100%;
      top: 50%;
      left: 0;
    }
    .text-wrap {
      position: relative;
      @include ib-grid(30, $gutter: 0);
      @include prefix(6, $gutter: 0);
      height: 100%;
      padding: 20px 0;

      .text {
        position: absolute;
        @include ib-grid(12, 30);
        @include prefix(1, 30);
      }
      .image-wrap {
        position: absolute;
        @include ib-grid(8, 30);
        @include suffix(3, 30);
        width: 214px;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
  }
  @include mobile-only {
    .text-wrap {
      position: relative;
      overflow: hidden;
      padding: 25px 0;

      .text {
        position: absolute;
        @include ib-grid(25, $gutter: 0);
        @include prefix(3, $gutter: 0);
        bottom: 25px;
      }
      .image-wrap {
        @include ib-grid(5.5, $gutter: 0);
        @include prefix(29.25);
      }
    }
  }
}

.no-accordion-spacer {
  background: $white;

  @include desktop-only {
    display: none;
  }
  @include mobile-only {
    height: 285px;
  }
}

.accordion-wrap {
  background: $grey-light;

  .section-listing {
    &:last-child {
      h4 {
        border-bottom: 1px solid $border-color;
      }
    }
    &.active {
      h4 {
        border-bottom: 1px solid $border-color;
      }
    }
    h4 {
      @include untitled(13px, $mobile-font-max: 12px);
      font-weight: 500;
      line-height: 1.3846153846;
      color: $green-medium;
      border-top: 1px solid $border-color;
      user-select: none;
      cursor: pointer;

      &:after {
        content: "";
        background: url("../i/svg/arrow-down.svg") no-repeat;
        background-size: cover;
      }
    }
    .resource-text,
    .text {
      @include untitled(14px, $mobile-font-max: 12px);
      line-height: 1.3571428571;
    }
    .resource-text {
      list-style: none;

      li {
        border-bottom: 1px solid rgba($green-medium, 0.2);

        &:last-child {
          border-bottom: 0;
        }

        a {
          color: $green-medium;
          @include stealth-link($weight: 0.06em);
        }
      }
    }
  }
  @include desktop-only {
    @include ib-grid(34, $gutter: 0);
    @include suffix(2, $gutter: 0);
    padding-bottom: 60px;

    .accordion-inner {
      position: relative;

      &.loading {
        .section-list {
          .text,
          .resource-text {
            display: block;
          }
        }
      }
    }
    .section-list {
      position: absolute;
      @include ib-grid(25, 34);
      @include prefix(7, 34);

      .section-listing {
        &.active {
          h4 {
            &:after {
              transform: translateY(-50%) rotateX(180deg);
            }
          }
          .text,
          .resource-text {
            display: block;
          }
        }
        h4 {
          position: relative;
          margin: 0;
          padding: 10px 0;

          &:after {
            position: absolute;
            display: block;
            width: 14px;
            height: 10px;
            top: 50%;
            right: 5px;
            transform: translateY(-50%);
          }
        }
        .text {
          @include ib-grid(17.5, 25);
          padding: 15px 0 30px;
          display: none;
        }
        .resource-text {
          display: none;
          margin: 0;
          padding: 0 0 25px;

          li {
            padding: 8px 0;

            .name {
              @include ib-grid(12.5, 25);
            }
          }
        }
      }
    }
  }
  @include mobile-only {
    padding: 45px 0;

    .accordion-inner {
      position: relative;
      @include ib-grid(30);
      @include prefix(3);

      &.loading {
        .section-list {
          .section-listing {
            .text,
            .resource-text {
              display: block;
            }
          }
        }
      }
      .section-list {
        .section-listing {
          &.active {
            h4 {
              &:after {
                transform: translateY(-50%) rotateX(180deg);
              }
            }
            .text,
            .resource-text {
              display: block;
            }
          }
          h4 {
            position: relative;
            margin: 0;
            padding: 10px 0;

            &:after {
              position: absolute;
              display: block;
              width: 14px;
              height: 10px;
              top: 50%;
              right: 5px;
              transform: translateY(-50%);
            }
          }
          .text {
            @include ib-grid(30, 30);
            padding: 15px 0 80px;
            display: none;
          }
          .resource-text {
            display: none;
            margin: 0;
            padding: 0 0 18px;

            li {
              padding: 6px 0;

              .name {
                @include ib-grid(15, 30);
              }
              .download-link {
                text-align: right;
                @include ib-grid(15, 30);
              }
            }
          }
        }
      }
    }
  }
}

.map-sizer {
  @include hardware-accelerated;
  position: relative;
  height: 0;

  &.clicked {
    .map-canvas {
      .leaflet-marker-icon {
        background: $map-icon-unselected-color;
        border: 3px solid $map-icon-unselected-color;
      }
    }
  }
  .map-canvas {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: inherit;
    mix-blend-mode: multiply;

    @include hover {
      .leaflet-control-zoom {
        visibility: inherit;
      }
    }

    &[data-interactive="false"] {
      .leaflet-control-zoom {
        display: none;
      }

      .leaflet-marker-pane {
        display: none;
      }
    }

    .leaflet-control-zoom {
      visibility: hidden;
    }

    .leaflet-marker-icon {
      @include untitled(14px);
      font-weight: 500;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 33px;
      border-radius: 50%;

      color: $white;
      background: $map-icon-color;
      border: 1px solid $white;

      &.clicked {
        background: $map-icon-color;
        border: 3px solid $map-icon-color;
        z-index: 21474 !important;
      }

      // @include hover {
      //   background: $white;
      //   color: $directory-page-primary-color;
      //   border: 3px solid $directory-page-primary-color;
      // }
      //
      // &.clicked {
      //   background: $white;
      //   color: $directory-page-primary-color;
      //   border: 3px solid $directory-page-primary-color;
      // }

      @include hover {
        z-index: $disabled-scroll-zindex - 1 !important;
      }
    }
  }
}
