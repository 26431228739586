$transition-speed: 1.5s;

.home-wrap {
  @include desktop-only {
    .state-home-transition-in &,
    .state-home-transition-out &,
    .state-home-open & {
      visibility: inherit;
      pointer-events: all;
    }
    .home-inner {
      .info-wrap {
        .main-nav,
        .open-wrap {
          will-change: transform;

          .state-initialised & {
            transition: transform $transition-speed;
          }
          .state-home-transition-prep & {
            transition: none;
          }
          .state-home-transition-in & {
            transform: translateX(-100%);
          }
          .state-home-open & {
            transform: translateX(0%);
          }
          .state-home-transition-out & {
            transform: translateX(-100%);
          }
          .state-resizing & {
            transition: none;
          }
        }
      }
      .bottom-bar {
        will-change: transform;

        .state-initialised & {
          transition: transform $transition-speed;
        }
        .state-home-transition-prep & {
          transition: none;
        }
        .state-resizing & {
          transition: none;
        }
        .state-home-transition-in & {
          transform: translateY(#{(100vw / $total-cols) * 7.5 * 1.5});

          @media screen and (min-width: 1065px) {
            transform: translateY(#{$footer-max-height * 1.5 + 1px});
          }
        }
        .state-home-open & {
          transform: translateY(0%);
        }
        .state-home-transition-out & {
          transform: translateY(#{100vw / $total-cols * 7.5 * 1.5});

          @media screen and (min-width: 1065px) {
            transform: translateY(#{$footer-max-height * 1.5 + 1px});
          }
        }
      }
      .feat-exhibition {
        will-change: transform;

        .state-initialised & {
          transition: transform $transition-speed;
        }
        .state-resizing & {
          transition: none;
        }
        .state-home-transition-in & {
          transform: translateX(-100vw);

          .text {
            opacity: 0;
          }
        }
        .state-home-open & {
          transform: translateX(0%);
        }
        .state-home-hover & {
          transform: translateX(#{-17/36 * 100vw}) translateX(#{17/36 * 15px});

          @include desktop-small-only {
            transform: translateX(#{-16.5/36 * 100vw}) translateX(#{16.5/36 * 15px});
          }
          @include desktop-medium-only {
            transform: translateX(#{-14/36 * 100vw}) translateX(#{14/36 * 15px});
          }
          @include desktop-large-only {
            transform: translateX(#{-12/36 * 100vw}) translateX(#{12/36 * 15px});
          }
          @include desktop-xl-only {
            transform: translateX(#{(1921px / $total-cols) * -10});
          }
        }
        .state-home-hover.state-resizing & {
          transition: none;
        }
        .state-home-transition-out & {
          transform: translateX(-100vw) translateX(-100%);
        }
      }
    }
  }
}

.home-footer {
  @include desktop-only {
    will-change: transform;

    .state-initialised & {
      transition: transform $transition-speed;
    }
    .state-home-transition-prep & {
      transition: none;
    }
    .state-resizing & {
      transition: none;
    }
    .state-home-transition-in & {
      display: block;
      transform: translateY(100%);
    }
    .state-home-open & {
      display: block;
      transform: translateY(0%);
    }
    .state-home-transition-out & {
      display: block;
      transform: translateY(100%);
    }
  }
}

header#header {
  @include desktop-only {
    will-change: transform;

    .state-initialised & {
      transition: transform $transition-speed;
    }
    .state-resizing & {
      transition: none;
    }
    .state-home-transition-prep & {
      transition: none;
    }
    .state-home-transition-in & {
      transform: translateX(0%);
      pointer-events: none;
    }
    .state-home-open & {
      transform: translateX(100%);
      pointer-events: none;
    }
    .state-home-transition-out & {
      transform: translateX(0%);
      pointer-events: none;
    }
  }
}

.goulburn-exhibitions {
  @include desktop-only {
    .exhibitions-list-wrap {
      will-change: transform;

      .state-initialised & {
        transition: transform $transition-speed;
      }
      .state-resizing & {
        transition: none;
      }
      .state-home-transition-in & {
        transform: translateX(0%);
        pointer-events: none;
      }
      .state-home-open & {
        transform: translateX(100%);
        pointer-events: none;
      }
      .state-home-open.state-home-transition-out & {
        transform: translateX(0%);
        pointer-events: none;
      }
    }
  }
}
