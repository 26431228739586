$max-exhibition-width: 1400px;

.exhibition-header {
  h1 {
    @include untitled(113.4px, $mobile-font-max: 60px);
    font-weight: 300;
    line-height: 0.9788359788;

    a:hover {
      color: $title-hover;
    }
  }
  h2 {
    @include untitled(30px, $mobile-font-max: 20px);
    line-height: 1.2;
  }
  .date {
    @include untitled(30px, $mobile-font-max: 20px);
    line-height: 1.2;
  }
  .caption {
    @include untitled(10px);
    color: $black;
  }
  .information {
    @include untitled(15px);

    span {
      @include text-link;
    }
  }
  @include desktop-only {
    overflow: visible;

    h1 {
      p {
        margin: 0;
      }
    }
  }
  @include mobile-only {
    h1 {
      p {
        margin: 0;
      }
    }
  }
}

.exhibition-body {
  @include desktop-only {
    visibility: hidden;
    opacity: 0;
    will-change: transform opacity;
    transition: #{opacity 0.25s, visibility 0.15s};

    &.static {
      margin-top: #{$header-height + 550px};
      opacity: 1;
    }
    &.fixed {
      position: fixed;
      width: 100%;
      left: 0;
      top: #{$header-height};
      opacity: 0;
      z-index: 0;

      &.active {
        z-index: auto;
      }
      .state-exhibition-scrolled & {
        opacity: 1;
        visibility: inherit;
      }
    }
  }
  @include mobile-only {
    &.static {
      display: none;
    }
  }
}

.exhibition-header.layout-center {
  @include desktop-only {
    .inner-wrap {
      position: relative;
      margin-bottom: 72px;
      padding-top: 120px;

      @media screen and (min-width: 1401px) {
        max-width: #{$max-exhibition-width};
        margin: 0 auto;
      }
      h1,
      h2 {
        margin: 0;
      }
      .title-wrap,
      .subtitle-wrap {
        @include ib-grid(18);
        @include prefix(6);
      }
      .title-wrap {
        margin-top: 58px;

        h1 {
          margin-bottom: 13px;
        }
        .bkg {
          position: absolute;
          height: calc(100% - 53px);
          width: 100%;
          left: 0;
          bottom: 53px;
          z-index: -1;

          @media screen and (min-width: 1401px) {
            width: 100vw;
            left: calc(((100vw) - #{$max-exhibition-width}) / -2);

            .state-scrollbar-active & {
              left: calc(((100vw - 15px) - #{$max-exhibition-width}) / -2);
            }
          }
        }
        .caption-wrap {
          display: none;
        }
      }
      .feat-exhibition {
        position: absolute;
        @include ib-grid(2, $gutter: 0);
        right: 0;
        top: 0;
        height: calc(100% - 53px);

        @media screen and (min-width: 1401px) {
          width: calc(((100vw) / #{$total-cols}) * 2);
          right: calc(((100vw) - #{$max-exhibition-width}) / -2);

          .state-scrollbar-active & {
            width: calc(((100vw - 15px) / #{$total-cols}) * 2);
            right: calc(((100vw - 15px) - #{$max-exhibition-width}) / -2);
          }
        }
        &:after {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: $featured-exhibition-overlay;
        }
      }
      .subtitle-wrap {
        position: absolute;
        left: 0;
        top: 100%;
      }
      .image-wrap {
        position: relative;
        @include ib-grid(28);
        @include prefix(4);
        @include suffix(4);
        margin-bottom: 77px;

        .date {
          position: absolute;
          @include ib-grid(9, 28);
          @include prefix(17, 28);
          top: calc(100% + 30px);
        }
      }
    }
  }
  @include mobile-only {
    &.link {
      .mobile-text {
        padding: 30px 0 42px;

        .date {
          margin-bottom: 20px;
        }
      }
    }
    &.no-link {
      .mobile-text {
        padding: 20px 0 28px;

        .subtitle-wrap {
          margin-bottom: 20px;
        }
        .date {
          margin-bottom: 20px;
        }
        &.mobile-text-lower {
          padding-top: 26px;

          .date {
            margin-bottom: 0;
          }
        }
      }
    }
    h1,
    h2 {
      margin: 0;
    }
    .mobile-text {
      .title-wrap,
      .subtitle-wrap,
      .date,
      .information {
        @include ib-grid(30);
        @include prefix(3);
        @include suffix(3);
      }
      .title-wrap {
        margin-bottom: 10px;
      }
      .subtitle-wrap {
        margin-bottom: 8px;
      }
      .date {
        p {
          margin: 0;
        }
      }
    }
  }
}

.exhibition-header.layout-center-tall {
  @include desktop-only {
    .inner-wrap {
      position: relative;
      margin-bottom: 72px;
      padding-top: 70px;

      @media screen and (min-width: 1401px) {
        position: relative;
        max-width: #{$max-exhibition-width};
        margin: -1px auto 72px;
      }
      h1,
      h2 {
        margin: 0;
      }
      .bkg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -1;

        @media screen and (min-width: 1401px) {
          &:before {
            position: absolute;
            content: "";
            top: 0;
            right: calc(100% - 1px);
            width: calc(((100vw) - #{$max-exhibition-width}) / 2 + 1px);
            height: 100%;
            background: inherit;

            .state-scrollbar-active & {
              width: calc(
                ((100vw - 15px) - #{$max-exhibition-width}) / 2 + 1px
              );
            }
          }
          &:after {
            position: absolute;
            content: "";
            top: 0;
            left: calc(100% - 1px);
            width: calc(((100vw) - #{$max-exhibition-width}) / 2 + 1px);
            height: 100%;
            background: inherit;

            .state-scrollbar-active & {
              width: calc(
                ((100vw - 15px) - #{$max-exhibition-width}) / 2 + 1px
              );
            }
          }
        }
      }
      .title-wrap {
        @include ib-grid(20);
        @include prefix(3);

        .caption-wrap {
          display: none;
        }
      }
      .feat-exhibition {
        position: absolute;
        @include ib-grid(2, $gutter: 0);
        right: 0;
        top: 0;
        height: 100%;

        @media screen and (min-width: 1401px) {
          width: calc(((100vw) / #{$total-cols}) * 2);
          right: calc(((100vw) - #{$max-exhibition-width}) / -2);

          .state-scrollbar-active & {
            width: calc(((100vw - 15px) / #{$total-cols}) * 2);
            right: calc(((100vw - 15px) - #{$max-exhibition-width}) / -2);
          }
        }
        &:after {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: $featured-exhibition-overlay;
        }
      }
      .image-wrap {
        position: relative;
        @include ib-grid(24);
        @include prefix(6);
        @include suffix(6);
        margin-top: -60px;
        margin-bottom: 35px;

        img {
          z-index: -1;
        }
      }
      .subtitle-wrap {
        position: relative;
        @include ib-grid(18, $gutter: 0);
        @include prefix(6, $gutter: 0);
        @include suffix(12, $gutter: 0);
      }
      .date {
        position: relative;
        @include ib-grid(24, $gutter: 0);
        @include prefix(6, $gutter: 0);
        @include suffix(6, $gutter: 0);
        margin-bottom: 45px;

        & > p {
          @include ib-grid(18, 24, $gutter: 0);
          @include suffix(6, 24, $gutter: 0);
          margin-bottom: 0;
        }
        .caption-wrap {
          display: none;
        }
      }
    }
  }
  @include mobile-only {
    .inner-wrap {
      position: relative;
    }
    &.link {
      .inner-wrap {
        padding: 30px 0 0;
      }
      .mobile-text {
        padding: 30px 0 42px;

        .date {
          margin-bottom: 20px;
        }
      }
    }
    &.no-link {
      .inner-wrap {
        padding: 0 0 18px;
      }
      .mobile-text {
        padding: 20px 0 28px;

        .subtitle-wrap {
          margin-bottom: 20px;
        }
        .date {
          margin-bottom: 20px;
        }
      }
    }
    h1,
    h2 {
      margin: 0;
    }
    .image-wrap {
      @include ib-grid(30);
      @include prefix(3);
      @include suffix(3);
    }
    .bkg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
    }
    .mobile-text {
      .title-wrap,
      .subtitle-wrap,
      .date,
      .information {
        @include ib-grid(30);
        @include prefix(3);
        @include suffix(3);
      }
      .title-wrap {
        margin-bottom: 10px;
      }
      .subtitle-wrap {
        margin-bottom: 8px;
      }
      .date {
        p {
          margin: 0;
        }
      }
    }
  }
}

.exhibition-header.layout-full-width {
  .caption {
    color: $white;
  }
  @include desktop-only {
    .inner-wrap {
      position: relative;
      padding-top: 120px;

      @media screen and (min-width: 1401px) {
        max-width: #{$max-exhibition-width};
        margin: 0 auto;
        margin-top: -1px;
      }
      h1,
      h2 {
        margin: 0;
      }
      .title-wrap {
        position: relative;
        @include ib-grid(29, $gutter: 0);
        @include prefix(5, $gutter: 0);
        margin-bottom: 30px;

        @media screen and (min-width: 1401px) {
          position: static;
        }
        h1 {
          margin-bottom: 35px;
        }
        h2 {
          margin-bottom: 15px;
        }
        .feat-exhibition {
          position: absolute;
          @include ib-grid(2, $gutter: 0);
          left: 100%;
          top: -120px;
          height: calc(100% + 120px + 30px);

          @media screen and (min-width: 1401px) {
            width: calc(((100vw) / #{$total-cols}) * 2);
            left: auto;
            right: calc(((100vw) - #{$max-exhibition-width}) / -2);
            height: 100%;
            top: 0;

            .state-scrollbar-active & {
              width: calc(((100vw - 15px) / #{$total-cols}) * 2);
              right: calc(((100vw - 15px) - #{$max-exhibition-width}) / -2);
            }
          }
          &:after {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: $featured-exhibition-overlay;
          }
        }
      }
      .bkg-wrap {
        padding-bottom: 45px;

        @media screen and (min-width: 1401px) {
          position: relative;

          &:before {
            position: absolute;
            background: inherit;
            content: "";
            top: 0;
            height: 100%;
            right: calc(100% - 1px);
            width: calc(((100vw) - #{$max-exhibition-width}) / 2 + 1px);

            .state-scrollbar-active & {
              width: calc(((100vw - 15px) - #{$max-exhibition-width}) / 2);
            }
          }
        }
        .image-wrap {
          position: relative;
          @include ib-grid(31, $gutter: 0);
          @include prefix(5, $gutter: 0);
          margin-bottom: 140px;
        }
        .caption-wrap {
          display: none;
        }
      }
    }
  }
  @include mobile-only {
    &.link {
      .mobile-text {
        padding: 60px 0 24px;

        .date {
          margin-bottom: 8px;
        }
      }
    }
    &.no-link {
      .mobile-text {
        padding: 20px 0 28px;

        .subtitle-wrap {
          margin-bottom: 20px;
        }
        .date {
          margin-bottom: 20px;
        }
      }
    }
    h1,
    h2 {
      margin: 0;
    }
    .mobile-text {
      .title-wrap,
      .subtitle-wrap,
      .date,
      .information {
        @include ib-grid(30);
        @include prefix(3);
        @include suffix(3);
      }
      .title-wrap {
        margin-bottom: 10px;
      }
      .date {
        p {
          margin: 0;
        }
      }
    }
  }
}

.exhibition-header.layout-right {
  @include desktop-only {
    .inner-wrap {
      position: relative;
      margin-top: -1px;
      padding-top: 120px;

      @media screen and (min-width: 1401px) {
        max-width: #{$max-exhibition-width};
        margin: 0 auto;

        &:before {
          position: absolute;
          content: "";
          width: calc((100vw - #{$max-exhibition-width}) / 2 + 1px);
          top: 0;
          height: 100%;
          right: calc(100% - 1px);
          background-color: inherit;

          .state-scrollbar-active & {
            width: calc(((100vw - 15px) - #{$max-exhibition-width}) / 2 + 1px);
          }
        }
      }
      h1,
      h2 {
        margin: 0;
      }
      .title-wrap {
        position: relative;
        @include ib-grid(28, $gutter: 0);
        @include prefix(6, $gutter: 0);
        margin-bottom: 30px;

        @media screen and (min-width: 1401px) {
          position: static;

          &:before {
            position: absolute;
            content: "";
            width: calc((100vw - #{$max-exhibition-width}) / 2 + 1px);
            top: 0;
            height: 100%;
            left: calc(100% - 1px);
            background-color: $white;

            .state-scrollbar-active & {
              width: calc(
                ((100vw - 15px) - #{$max-exhibition-width}) / 2 + 1px
              );
            }
          }
        }
        h1 {
          margin-bottom: 35px;
        }
        h2 {
          margin-bottom: 15px;
        }
        .feat-exhibition {
          position: absolute;
          @include ib-grid(2, 28, $gutter: 0);
          left: 100%;
          top: -120px;
          height: calc(100% + 120px + 30px);

          @media screen and (min-width: 1401px) {
            width: calc(((100vw) / #{$total-cols}) * 2);
            left: auto;
            right: calc(((100vw) - #{$max-exhibition-width}) / -2);
            top: 0;
            height: 100%;

            .state-scrollbar-active & {
              width: calc(((100vw - 15px) / #{$total-cols}) * 2);
              right: calc(((100vw - 15px) - #{$max-exhibition-width}) / -2);
            }
          }
          &:after {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: $featured-exhibition-overlay;
          }
        }
      }
      .bkg-wrap {
        position: relative;

        .image-wrap {
          position: relative;
          @include ib-grid(30, $gutter: 0);
          @include prefix(6);

          .image-inner {
            max-width: 730px;
          }
          &:before {
            position: absolute;
            display: block;
            content: "";
            width: 100%;
            height: calc(100% + 1px);
            top: 0;
            background-color: inherit;
          }
          @media screen and (min-width: 1401px) {
            &:after {
              position: absolute;
              content: "";
              top: 0;
              left: 100%;
              width: calc(((100vw) - #{$max-exhibition-width}) / 2);
              height: calc(100% + 1px);
              background: inherit;

              .state-scrollbar-active & {
                width: calc(((100vw - 15px) - #{$max-exhibition-width}) / 2);
              }
            }
          }
        }
        .caption-wrap {
          padding: 50px 0 30px;

          @media screen and (min-width: 1401px) {
            position: relative;

            &:before {
              position: absolute;
              content: "";
              top: 0;
              right: calc(100% - 1px);
              width: calc(((100vw) - #{$max-exhibition-width}) / 2 + 1px);
              height: 100%;
              background: inherit;

              .state-scrollbar-active & {
                width: calc(
                  ((100vw - 15px) - #{$max-exhibition-width}) / 2 + 1px
                );
              }
            }
            &:after {
              position: absolute;
              content: "";
              top: 0;
              left: calc(100% - 1px);
              width: calc(((100vw) - #{$max-exhibition-width}) / 2 + 1px);
              height: 100%;
              background: inherit;

              .state-scrollbar-active & {
                width: calc(
                  ((100vw - 15px) - #{$max-exhibition-width}) / 2 + 1px
                );
              }
            }
          }
          .caption {
            display: none;
          }
        }
      }
    }
  }
  @include mobile-only {
    &.link {
      margin-bottom: 65px;

      .mobile-text {
        padding: 60px 0 24px;

        .date {
          margin-bottom: 8px;
        }
      }
    }
    &.no-link {
      .mobile-text {
        padding: 20px 0 28px;

        .subtitle-wrap {
          margin-bottom: 20px;
        }
        .date {
          margin-bottom: 20px;
        }
      }
    }
    h1,
    h2 {
      margin: 0;
    }
    .mobile-text {
      .title-wrap,
      .subtitle-wrap,
      .date,
      .information {
        @include ib-grid(30);
        @include prefix(3);
        @include suffix(3);
      }
      .title-wrap {
        margin-bottom: 10px;
      }
      .date {
        p {
          margin: 0;
        }
      }
    }
    .image-wrap {
      @include ib-grid(28);
      @include prefix(3);
      @include suffix(5);
    }
  }
}

.exhibition-header.layout-portrait {
  h1 {
    @include untitled(113.4px, $mobile-font-max: 60px);
    font-size: 11.34vw;
    font-weight: 300;
    line-height: 0.9788359788;

    @include mobile-only {
      @include untitled(113.4px, $mobile-font-max: 60px);
    }
  }
  h2 {
    @include untitled(30px, $mobile-font-max: 20px);
    font-size: 3vw;
    line-height: 1.1666666667;

    @include mobile-only {
      font-size: 20px;
    }
  }
  .date {
    @include untitled(30px, $mobile-font-max: 20px);
    line-height: 1.1666666667;
  }
  .caption-wrap {
    background: $white;
  }
  @include desktop-only {
    position: relative;

    h1,
    h2 {
      margin: 0;
    }
    .inner-wrap {
      padding-top: 120px;

      @media screen and (min-width: 1401px) {
        position: relative;
        max-width: #{$max-exhibition-width};
        margin: 0 auto;

        &:before {
          position: absolute;
          content: "";
          top: 0;
          height: 100%;
          right: calc(100% - 1px);
          width: calc((100vw - #{$max-exhibition-width}) / 2 + 1px);
          background-color: inherit;

          .state-scrollbar-active & {
            width: calc(((100vw -15px) - #{$max-exhibition-width}) / 2 + 1px);
          }
        }
      }
    }
    .title-wrap {
      @include ib-grid(17);
      @include prefix(14);
      @include suffix(5);
      margin-bottom: 25px;

      h1 {
        margin-bottom: 13px;
      }
    }
    .feat-exhibition {
      position: absolute;
      @include ib-grid(2, $gutter: 0);
      right: 0;
      top: 0;
      height: 100%;
      z-index: -1;

      @media screen and (min-width: 1401px) {
        width: calc(((100vw) / #{$total-cols}) * 2);
        right: calc(((100vw) - #{$max-exhibition-width}) / -2);

        .state-scrollbar-active & {
          width: calc(((100vw - 15px) / #{$total-cols}) * 2);
          right: calc(((100vw - 15px) - #{$max-exhibition-width}) / -2);
        }
      }
      &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: $featured-exhibition-overlay;
      }
    }
    .bkg-wrap {
      position: relative;

      .image-wrap {
        position: relative;
        @include ib-grid(17);
        @include prefix(14);
        @include suffix(5);
        overflow: hidden;

        img {
          z-index: 5;
        }
      }
      .bkg-1 {
        position: absolute;
        @include ib-grid(15, $gutter: 0);
        height: 75%;
        left: 0;
        top: 0;

        @media screen and (min-width: 1401px) {
          &:before {
            position: absolute;
            content: "";
            height: 100%;
            right: calc(100% - 1px);
            top: 0;
            width: calc((100vw - #{$max-exhibition-width}) / 2 + 1px);
            background: inherit;

            .state-scrollbar-active & {
              width: calc(
                ((100vw - 15px) - #{$max-exhibition-width}) / 2 + 1px
              );
            }
          }
        }
        .date {
          position: absolute;
          @include ib-grid(11, 15);
          @include prefix(2, 15);
          bottom: 30px;
        }
      }
      .bkg-2 {
        position: absolute;
        @include ib-grid(6, $gutter: 0);
        height: 55%;
        right: 0;
        bottom: 1px;

        @media screen and (min-width: 1401px) {
          &:after {
            position: absolute;
            content: "";
            height: 100%;
            left: calc(100% - 1px);
            top: 0;
            width: calc(((100vw) - #{$max-exhibition-width}) / 2 + 1px);
            background: inherit;

            .state-scrollbar-active & {
              width: calc(
                ((100vw - 15px) - #{$max-exhibition-width}) / 2 + 1px
              );
            }
          }
        }
      }
    }
    .caption-wrap {
      display: none;

      @media screen and (min-width: 1401px) {
        position: relative;

        &:after {
          position: absolute;
          content: "";
          width: calc(((100vw - 15px) - #{$max-exhibition-width}) / 2);
          background: $white;
          left: 100%;
          top: 0;
          height: 100%;
        }
      }
    }
  }
  @include mobile-only {
    &.link {
      margin-bottom: 65px;

      .mobile-text {
        padding: 60px 0 24px;

        .date {
          margin-bottom: 8px;
        }
      }
    }
    &.no-link {
      .mobile-text {
        padding: 20px 0 28px;

        .subtitle-wrap {
          margin-bottom: 20px;
        }
        .date {
          margin-bottom: 20px;
        }
      }
    }
    h1,
    h2 {
      margin: 0;
    }
    .mobile-text {
      .title-wrap,
      .subtitle-wrap,
      .date,
      .information {
        @include ib-grid(30);
        @include prefix(3);
        @include suffix(3);
      }
      .title-wrap {
        margin-bottom: 10px;
      }
      .subtitle-wrap {
        margin-bottom: 8px;
      }
      .date {
        p {
          margin: 0;
        }
      }
    }
    .bkg-wrap {
      position: relative;

      .image-wrap {
        @include ib-grid(25.5, $gutter: 0);
        @include prefix(10.5, $gutter: 0);
      }
      .bkg-1 {
        position: absolute;
        @include ib-grid(10.5, $gutter: 0);
        left: 0;
        top: 0;
        height: 50%;
      }
    }
  }
}

article.goulburn-exhibitions {
  @include desktop-only {
    position: relative;
  }
  .feature-list {
    @include desktop-only {
      margin-bottom: 95px;

      .exhibition-header {
        margin-bottom: 160px;

        &.layout-center {
          margin-bottom: 232px;
        }
        &.layout-full-width,
        &.layout-portrait,
        &.layout-right,
        &.layout-center-tall {
          margin-bottom: 0;
        }
        &.layout-center-tall {
          .inner-wrap {
            margin-bottom: 0;
          }
        }
      }
      .exhibition-detail-wrap {
        .text {
          @include ib-grid(15);
          @include prefix(6);
        }
      }
      .exhibition-layout-spacer {
        display: none;
      }
    }
    @include mobile-only {
      margin-bottom: 65px;

      .exhibition-header {
        &:last-child {
          margin-bottom: 0;
        }
      }
      .exhibition-layout-spacer {
        height: 200px;
      }
    }
  }
}

.exhibitions-list-wrap {
  @include desktop-only {
    background: $white;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 11;

    .goulburn-exhibitions-archive & {
      position: static;
      width: auto;
      top: auto;
      left: auto;
      z-index: auto;
    }
  }
  .std-list-wrap {
    h2 {
      @include untitled(30px, $mobile-font-max: 26px);
      line-height: 1.3333333333;
      font-weight: 500;
    }
    a.archive {
      @include untitled(15px, $mobile-font-max: 12px);
      color: $green-medium;
      line-height: 1.3333333333;
      font-weight: 500;
      letter-spacing: -0.5px;
    }
    .exhibition-listing {
      border-top: 1px solid $green-medium;

      &:hover {
        color: $green-medium;
      }
      h5 {
        @include untitled(14px, $mobile-font-max: 12px);
        font-weight: 500;
        line-height: 1.2142857143;
        color: $green-medium;
      }
      p {
        @include untitled(14px, $mobile-font-max: 12px);
        line-height: 1.2142857143;
      }
    }
    @include desktop-only {
      @include ib-grid(22);
      @include prefix(6);
      @include suffix(8);
      margin-bottom: 95px;

      h2 {
        margin: 0 0 15px;
      }
      .exhibition-listing {
        display: block;
        margin-bottom: 40px;
        padding-top: 5px;

        h5 {
          margin: 0 0 5px;
        }
        p {
          margin: 0;
        }
      }
    }
    @include mobile-only {
      @include ib-grid(30);
      @include prefix(3);
      @include suffix(3);
      margin-bottom: 65px;

      h2 {
        margin: 0 0 15px;
      }
      .exhibition-listing {
        display: block;
        margin-bottom: 20px;
        padding-top: 3px;

        h5 {
          margin: 0 0 8px;
        }
        p {
          margin: 0;
        }
      }
    }
  }
}

.exhibition-detail-wrap {
  color: $black-true;

  @include mobile-only {
    @include untitled(12px);
  }
  h3,
  h4 {
    @include untitled(18px);
  }
  & > .caption {
    @include untitled(10px);
  }
  .exhibition-event-wrap {
    h3 {
      @include untitled(18px);
      font-weight: 500;
      line-height: 1.3888888889;

      @include mobile-only {
        @include untitled(26px);
      }
    }
  }
  @include desktop-only {
    @include ib-grid(24);
    @include prefix(6);
    @include suffix(6);
    margin-bottom: 200px;

    &.has-images,
    &.has-sponsors {
      margin-bottom: 0;
    }
    &.has-images {
      & > .text {
        min-height: 515px;
      }
    }
    h3 {
      margin: 0;
    }
    .title-wrap {
      @include ib-grid(17, 24);
      @include suffix(7, 24);
    }
    .date-wrap {
      @include ib-grid(7, 24);
      @include prefix(17, 24);
      margin-top: 50px;
      margin-bottom: 40px;
    }
    & > .text {
      @include ib-grid(14, 24, $gutter: 0);
      margin-bottom: 65px;
      max-width: 430px;
    }
    .exhibition-event-wrap {
      max-width: 725px;

      h3 {
        margin-bottom: 10px;
      }
      .event-list {
        margin-bottom: 75px;

        .event-listing {
          display: block;
          margin-bottom: 20px;
          padding-top: 10px;

          &:last-child {
            margin-bottom: 0;
          }
          h4 {
            margin: 0 0 5px;
          }
          .details {
            .category {
              @include ib-grid(5.5, 24, $gutter: 0);
            }
            .date {
              @include ib-grid(7, 24);
            }
            .time {
              @include ib-grid(3.5, 24);
            }
            .location {
              @include ib-grid(8, 24);
            }
          }
        }
      }
    }
  }
  @include mobile-only {
    @include ib-grid(30);
    @include prefix(3);
    @include suffix(3);
    padding-top: 10px;

    h3 {
      margin: 0;
    }
    .title-wrap,
    .date-wrap {
      display: none;
    }
    & > .caption {
      margin-bottom: 30px;
    }
    & > .text {
      margin-bottom: 85px;
    }
    .exhibition-event-wrap {
      h3 {
        margin-bottom: 20px;
      }
      .event-list {
        margin-bottom: 60px;

        .event-listing {
          display: block;
          margin-bottom: 35px;
          padding-top: 5px;

          &:last-child {
            margin-bottom: 0;
          }
          h4 {
            margin: 0 0 2px;
          }
          .details {
            .category,
            .location {
              display: none;
            }
            .date,
            .time {
              display: block;
            }
          }
        }
      }
    }
  }
}

.exhibition-carousel-wrap {
  .carousel-info-wrap {
    @include untitled(10px);
    line-height: 1.3;
  }
  .carousel-item {
    background: $grey-light;
  }
  @include carousel-controls;
  @include desktop-only {
    position: relative;
    @include ib-grid(24, $gutter: 0);
    margin-bottom: 75px;

    .carousel-item {
      .image-wrap {
        &.is-portrait {
          .image {
            position: absolute;
            height: 100%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);

            img {
              position: static;
              width: auto;
              height: 100%;
            }
          }
        }
      }
    }
    .carousel-info-wrap {
      position: absolute;
      @include ib-grid(3.5, 24, $gutter: 0);
      @include prefix(0.5, 24, $gutter: 0);
      left: 100%;
      top: 0;

      .carousel-status {
        margin-bottom: 10px;
      }
      .caption-list {
        .caption-listing {
          display: none;
        }
      }
    }
  }
  @include mobile-only {
    position: relative;
    margin-bottom: 45px;

    .carousel-item {
      .image-wrap {
        &.is-portrait {
          .image {
            position: absolute;
            height: 100%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);

            img {
              position: static;
              width: auto;
              height: 100%;
            }
          }
        }
      }
    }
    .carousel-info-wrap {
      @include ib-grid(30);
      @include prefix(3);
      @include suffix(3);
      margin-top: 10px;

      .carousel-status {
        margin-bottom: 10px;
      }
      .caption-list {
        .caption-listing {
          display: none;
        }
      }
    }
  }
}

.exhibition-sponsors-wrap {
  .sponsor-list {
    border-bottom: 1px solid $green-medium;

    &:last-child {
      border-bottom: 0;
    }
  }
  @include desktop-only {
    @include ib-grid(22);
    @include suffix(12);
    margin-bottom: 95px;

    &.no-carousel {
      @include ib-grid(24);
      @include prefix(6);
      @include suffix(6);

      .sponsor-list {
        .sponsor-listing {
          @include ib-grid(6, 24);
          @include prefix(1, 24);

          &:last-child {
            @include prefix(0, $gutter: 0);
          }
        }
      }
    }
    &.no-sponsors {
      height: 120px;
    }
    .sponsor-list {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
      .sponsor-listing {
        @include ib-grid(6, 22);
        @include prefix(1, 22);
        height: 52px;
        margin-bottom: 25px;

        .image-wrap {
          height: 100%;

          .image {
            height: 100%;

            img {
              position: static;
              height: 100%;
              width: auto;
            }
          }
        }
      }
    }
  }
  @include mobile-only {
    margin-bottom: 60px;

    .sponsor-list {
      @include ib-grid(30);
      @include prefix(3);
      @include suffix(3);

      &:last-child {
        margin-bottom: 0;
      }
      .sponsor-listing {
        display: inline-block;
        height: 34px;
        margin-right: 30px;
        margin-bottom: 25px;

        .image-wrap {
          height: 100%;

          .image {
            height: 100%;

            img {
              position: static;
              height: 100%;
              width: auto;
            }
          }
        }
      }
    }
  }
}

.exhibition-thumb-list {
  & > .bkg {
    background: $page-bottom-bar;
  }
  .exhibition-listing {
    background: $grey-light;

    &.layout-promo {
      background: $yellow;

      h3 {
        @include untitled(39px);
        font-size: 3.9vw;
        line-height: 1.0070600101;

        @include mobile-only {
          @include untitled(44px);
          line-height: 1.0070600101;
        }
      }
    }
    h3 {
      @include untitled(40px);
      font-size: 4vw;
      font-weight: 300;
      line-height: 1.0070600101;

      @include mobile-only {
        @include untitled(44px);
        line-height: 1.0070600101;
      }
    }
    h4 {
      @include untitled(15px);
      font-size: 1.5vw;
      line-height: 1.2666666667;

      @include mobile-only {
        @include untitled(16px);
        line-height: 1.2666666667;
      }
    }
  }
  @include desktop-only {
    display: flex;
    flex-wrap: wrap;

    & > .bkg {
      @include ib-grid(24, $gutter: 0);
      @include suffix(12, $gutter: 0);
      padding-top: percentage(2 / $total-cols);
    }
    .exhibition-listing {
      position: relative;
      @include ib-grid(12, $gutter: 0);
      padding-top: 40px;
      overflow: hidden;

      .exhibition-inner {
        height: 100%;
      }
      &.layout-promo {
        .exhibition-inner {
          height: auto;
        }
      }
      &.layout-center {
        .image-wrap {
          @include ib-grid(10, 12);
          @include prefix(1, 12);
        }
      }
      &.layout-right {
        .image-wrap {
          @include ib-grid(9, 12, $gutter: 0);
          @include prefix(3, 12, $gutter: 0);
          visibility: hidden;

          &:last-child {
            position: absolute;
            bottom: 0;
            left: 0;
            visibility: inherit;
          }
        }
      }
      &.layout-full-width {
        .image-wrap {
          &:before {
            position: absolute;
            width: 100%;
            height: 100%;
            content: "";
            background-color: inherit;
          }
        }
      }
      &.layout-portrait {
        .bkg-wrap {
          position: relative;

          .image-wrap {
            @include ib-grid(6, 12);
            @include prefix(5, 12);

            img {
              width: calc(100% + 1px);
              z-index: 1;
            }
          }
          .bkg-1 {
            position: absolute;
            @include ib-grid(6, 12, $gutter: 0);
            height: 100%;
            left: 0;
            top: 0;
          }
          .bkg-2 {
            position: absolute;
            @include ib-grid(5, 12, $gutter: 0);
            @include prefix(10, 12);
            height: 55%;
            left: 0;
            bottom: 0;
          }
        }
      }
      &.layout-center-tall {
        .image-wrap {
          margin: 0 auto;
          max-height: 293px;
          overflow: hidden;
        }
        .title-wrap {
          margin-bottom: 0;
          padding: 35px 0 25px;
          min-height: 0;
        }
      }
      &.layout-promo {
        .title-wrap {
          position: absolute;
          bottom: 0;
          margin-bottom: 33px;

          h3 {
            margin-bottom: 35px;

            p {
              margin: 0;
            }
          }
        }
      }
      .title-wrap {
        @include ib-grid(10, 12);
        @include prefix(1, 12);
        @include suffix(1, 12);
        min-height: 154px;
        margin-bottom: 50px;

        @include desktop-medium-only {
          @include ib-grid(9, 12);
          @include prefix(1, 12);
          @include suffix(2, 12);
        }
        @include desktop-large-only {
          @include ib-grid(9, 12);
          @include prefix(1, 12);
          @include suffix(2, 12);
        }
        @include desktop-xl-only {
          @include ib-grid(9, 12);
          @include prefix(1, 12);
          @include suffix(2, 12);
        }
        h3 {
          margin: 0 0 15px;
        }
        h4 {
          margin: 0;
        }
      }
    }
  }
  @include mobile-only {
    .exhibition-listing {
      position: relative;
      display: block;
      padding-top: 42px;

      &.layout-portrait {
        .bkg-wrap {
          position: relative;

          .image-wrap {
            @include ib-grid(25.5, $gutter: 0);
            @include prefix(10.5, $gutter: 0);
          }
          .bkg-1 {
            position: absolute;
            @include ib-grid(10.5, $gutter: 0);
            height: 50%;
            left: 0;
            top: 0;
          }
          .bkg-2 {
            display: none;
          }
        }
      }
      &.layout-center-tall {
        .image-wrap {
          margin-bottom: 25px;
        }
        .title-wrap {
          margin-bottom: 25px;
          min-height: 0;
        }
      }
      &.layout-right {
        .image-wrap {
          &:last-child {
            display: none;
          }
        }
      }
      &.layout-promo {
        min-height: 320px;
      }
      .title-wrap {
        @include ib-grid(30);
        @include prefix(3);
        @include suffix(3);
        margin-bottom: 42px;

        h3 {
          margin: 0 0 28px;
        }
        h4 {
          margin: 0;
        }
      }
    }
  }
}

.exhibition-wrap {
  .exhibition-header {
    @include desktop-only {
      background: $white;
      margin-bottom: 295px;

      .inner-wrap {
        background: $white;
      }
      &.layout-right {
        .inner-wrap {
          .bkg-wrap {
            .caption-wrap {
              .caption {
                @include ib-grid(5);
                @include prefix(30);
              }
            }
          }
        }
      }
      &.layout-center {
        .inner-wrap {
          &:after {
            position: absolute;
            width: 100vw;
            height: #{255px + 53px};
            top: calc(100% - 53px);
            left: 50%;
            content: "";
            background: $white;
            transform: translateX(-50%);
            z-index: -1;
          }
          .title-wrap {
            .caption-wrap {
              position: absolute;
              @include ib-grid(5, $gutter: 0);
              @include prefix(29, $gutter: 0);
              left: 0;
              top: calc(100% - 23px);
            }
          }
        }
      }
      &.layout-center-tall {
        .inner-wrap {
          .date {
            .caption-wrap {
              position: absolute;
              @include ib-grid(5, 24, $gutter: 0);
              @include prefix(19, 24, $gutter: 0);
              left: 0;
              top: 0;
            }
          }
        }
      }
      &.layout-portrait {
        .inner-wrap {
          .caption-wrap {
            @include ib-grid(5, $gutter: 0);
            @include prefix(31);
            padding: 45px 0 55px;

            .caption {
              @include ib-grid(4, 5);
            }
          }
        }
      }
      &.layout-full-width {
        .inner-wrap {
          .bkg-wrap {
            .caption-wrap {
              @include ib-grid(5, $gutter: 0);
              @include prefix(29, $gutter: 0);
            }
          }
        }
      }
      .inner-wrap {
        z-index: 1;
      }
    }
  }
}

.goulburn-exhibitions-archive {
  .exhibitions-list-wrap {
    background: $white;
  }
  @include desktop-only {
    .exhibitions-list-wrap {
      padding-top: 90px;
      min-height: $page-min-height;
      padding-bottom: calc((100% / #{$total-cols}) * 2 + 45px);

      &:after {
        position: absolute;
        content: "";
        background: $page-bottom-bar;
        @include ib-grid(24, $gutter: 0);
        @include suffix(12, $gutter: 0);
        left: 0;
        bottom: 0;
        padding-top: percentage(2 / $total-cols);
      }
      .std-list-wrap {
        @include ib-grid(26, $gutter: 0);
        @include prefix(7);
        @include suffix(3);
      }
    }
  }
  @include mobile-only {
    .exhibitions-list-wrap {
      margin-top: 65px;
    }
  }
}
