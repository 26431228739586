// Theme
$black-true: #000000;
$black-dark: #242424;
$black: #3e3e3e;
$white: #fff;
$grey-light: #fafafa;
$grey-light-people: #f7f8f9;
$grey-medium-light: #efefef;
$grey-medium: #e8e8e8;
$grey-dark: #cccccc;

$green-lightest: #d5edd0;
$green-light: #0bb668;
$green-medium-light: #49ab7a;
$green-medium: #00a75c;
$green-dark: #00783d;

$green-home: #01a85e;

$yellow: #f8e910;

$red: #ed2324;

$nav-text-color: #232323;
$cart-color: #98d789;
$header-color: $green-light;
$footer-text-color: $black-dark;
$border-color: rgba($green-medium, 0.6);
$link-color: $green-medium;
$map-icon-color: $green-medium;
$map-icon-unselected-color: $grey-medium;
$page-bottom-bar: $green-lightest;
$featured-exhibition-overlay: rgba($white, 0.4);
$title-hover: #525252;

$transition-speed: 0.75s;
$bezier: cubic-bezier(0.29, 0.49, 0, 0.97);
$header-height: 135px;
$header-slim-height: 72px;
$bottom-bar-height: 58px;
$footer-max-height: 197px;

$header-mobile-height: 147px;
$cart-mobile-height: 46px;

// largest 32-bit integer
$overlay-zindex: 2147483647;
$disabled-scroll-zindex: $overlay-zindex - 1;

// Structural constants and helper mixins

$min-fluid-width: 320px;
$max-fluid-width: 6000px;
$max-desktop-small: 1200px;

$design-width: 1000px; // reference width from the design
$design-gutter: 2.5px; // actually 5px
$max-mobile: 959px; // includes ipad portrait if bigger than $max-phone
$max-tablet: 959px;
$total-cols: 36;
$col-width: percentage(1 / $total-cols);
$default-gutter: percentage($design-gutter / $design-width);
$fallback-width: 1200px; // used in place of 100vw where vw not supported
$mobile-padding: 15px;
$short-screen-cutoff: 720px;
$footer-height: calc((100vw / #{$total-cols}) * 7.5);
$footer-height-scroll: calc(((100vw - 15px) / #{$total-cols}) * 7.5);

@mixin grid-container {
  max-width: $max-fluid-width;
  min-width: $min-fluid-width - $mobile-padding * 2;
  margin: 0 auto;
}

@mixin gutters($gutter: $default-gutter, $parent-cols: $total-cols) {
  // Add left/right margin according to the grid gutter width.
  $factor: $total-cols / $parent-cols;
  margin-left: $gutter * $factor;
  margin-right: $gutter * $factor;
}

@function col-width(
  $cols,
  $parent-cols: $total-cols,
  $gutter: $default-gutter
) {
  $factor: $total-cols / $parent-cols;
  @return ($cols * $col-width - $gutter * 2) * $factor;
}

@mixin cols($cols, $parent-cols: $total-cols, $gutter: $default-gutter) {
  // Set width to the specified number of cols, with gutters.
  width: col-width($cols, $parent-cols, $gutter);
  @include gutters($gutter, $parent-cols);
}

@mixin ib-grid($width: null, $args...) {
  // wrapper for cols() which also makes the element an inline-block column
  // if first argument is a percentage, use that for width. Otherwise calculate
  // width according to the grid
  display: inline-block;
  vertical-align: top;
  @if $width {
    @if unit($width) == "%" {
      width: $width;
    } @else {
      @include cols($width, $args...);
    }
  }
}

@mixin prefix(
  $cols: 0,
  $parent-cols: $total-cols,
  $gutter: $default-gutter,
  $prop: margin
) {
  // Add left margin of the specified number of cols
  $factor: $total-cols / $parent-cols;
  #{$prop}-left: (($col-width * $cols) + $gutter) * $factor;
}

@mixin suffix(
  $cols: 0,
  $parent-cols: $total-cols,
  $gutter: $default-gutter,
  $prop: margin
) {
  // Add right margin of the specified number of cols
  $factor: $total-cols / $parent-cols;
  #{$prop}-right: (($col-width * $cols) + $gutter) * $factor;
}

@function grid-px($cols, $gutter: $default-gutter) {
  // Calculate the pixel width of a fluid grid element, at $design-width
  @return round(($cols / $total-cols - 2 * $gutter / 100%) * $design-width);
}

@mixin vw-prop(
  $prop,
  $design-val: 0,
  $min-width: ($max-mobile + 1),
  $max-width: $max-fluid-width,
  $val: 0
) {
  // Set the value of $prop such that the value is fluid when the browser
  // width is within $min-width and $max-width, and fixed outside this range.
  // When the browser width is within the range, use vw, and outside use px.
  // If $design-val is set, use it to derive $val, as a percentage (in vw) of
  // $design-width. Otherwise, use $val to derive the min and max pixel values.

  @if ($design-val != 0) {
    // assume $design-val is in px
    $val: $design-val / $design-width * 100;
  }

  @if $allow-mq {
    @include restricted-vw-property($prop, $val, $min-width, $max-width);
  } @else {
    #{$prop}: $val * $fallback-width / 100;
  }
}

@mixin desktop-only {
  @if $allow-mq {
    @media screen and (min-width: #{$max-mobile + 1}) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin desktop-ipad-only {
  @if $allow-mq {
    @media screen and (min-width: #{$max-mobile + 1}) and (max-width: 1000px) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin desktop-small-only {
  @if $allow-mq {
    @media screen and (min-width: 1001px) and (max-width: 1200px) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin desktop-medium-only {
  @if $allow-mq {
    @media screen and (min-width: 1201px) and (max-width: 1440px) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin desktop-large-only {
  @if $allow-mq {
    @media screen and (min-width: 1441px) and (max-width: 2000px) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin desktop-xl-only {
  @if $allow-mq {
    @media screen and (min-width: 1921px) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin mobile-only {
  @if $allow-mq {
    @media screen and (max-width: $max-mobile) {
      @content;
    }
  }
}

@mixin ratio-alt-only {
  @if $allow-mq {
    @media screen and (max-aspect-ratio: 5/4) {
      @content;
    }
  }
}

// Miscellaneous constants and helpers
// see also constants.js

$content-nav-height: 50px;
$min-header-height: 600px;
$nav-links-height: 120px;

@mixin anchor-offset($padding: 0) {
  padding-top: $content-nav-height + $padding;
  margin-top: $content-nav-height * -1;
}

@mixin underline($color: currentColor, $weight: 0.08em, $offset: 0.02em) {
  text-decoration: none;
  background-image: unquote("linear-gradient(") to top, transparent $offset,
    $color $offset, $color ($offset + $weight),
    transparent ($offset + $weight) unquote(")");
}
@mixin no-underline {
  background-image: none;
}

@mixin hover-state($touch-default: false) {
  /* Enable hover states on non-touch devices only. If $touch-default,
     default to the hovered state for touch devices.
     Requires Modernizer.touchevents */

  .no-touchevents &:hover {
    @content;
  }
  @if $touch-default {
    .touchevents & {
      @content;
    }
  }
}
