$hamburger-layer-color: $black-true;
$hamburger-layer-border-radius: 0px;
$hamburger-layer-width: 38px;
$hamburger-layer-height: 4px;
$hamburger-layer-spacing: 7px;
$hamburger-padding-y: 10px;

@import "~hamburgers/_sass/hamburgers/hamburgers";

.hamburger {
  padding: 14px 0;
  outline: none;

  .hamburger-inner {
    &:after {
      display: none;
    }
  }
}
