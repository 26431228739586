@mixin lock-screen {
  width: 100% !important;
  height: calc(100vh - 1px) !important;
  overflow: hidden !important;
}

html {
  overflow-y: scroll;
  font-kerning: normal;

  &.state-loading {
    @include lock-screen;
    overflow-y: visible !important;
  }
  @include desktop-only {
    &.state-overlay-visible,
    &.state-cart-open {
      @include lock-screen;
      padding-right: 15px;

      &.state-cart-fixed {
        .cart-wrap {
          width: calc(100% - 15px);
        }
      }
      header#header {
        width: calc(100% - 15px);
      }
    }
    &.state-home-open {
      @include lock-screen;
    }
  }
  @include mobile-only {
    &.state-menu-visible,
    &.state-mobile-transition,
    &.state-cart-open {
      @include lock-screen;
    }
    &.state-mobile-subscribe-open {
      position: fixed;
    }
  }
}
body {
  position: relative;
  background: $white;
  color: $black-dark;
  visibility: hidden;
  overflow: hidden;

  &.state-initialised {
    visibility: inherit;
  }
}

.content-wrap {
  position: relative;

  & > #content {
    > article {
      @include grid-container;
      height: 100%;
      min-height: 900px;
    }
  }
}

.scrollbar-check-wrap {
  @include desktop-only {
    position: absolute;
    width: 100%;
    bottom: 0;
    opacity: 0;

    .scrollbar-screen,
    .scrollbar-width {
      position: absolute;
    }
    .scrollbar-screen {
      width: 100vw;
    }
    .scrollbar-width {
      width: 100%;
    }
  }
  @include mobile-only {
    display: none;
  }
}

.no-desktop {
  @include desktop-only {
    display: none !important;
  }
}

.no-mobile {
  @include mobile-only {
    display: none !important;
  }
}
