$max-width: 1921px;

.home-wrap {
  background: $grey-light;

  @include desktop-xl-only {
    @media screen and (min-width: 2320px) {
      &:before {
        position: absolute;
        width: calc((100vw - #{$max-width}) / 5 * 1);
        background: $green-dark;
        content: "";
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
      }
    }
  }
  @include mobile-only {
    display: none;
  }

  .home-inner {
    & > .logo-wrap {
      background: $grey-medium-light;

      @include desktop-only {
        @include desktop-large-only {
          background: $white;
        }
        @include desktop-xl-only {
          background: $white;
        }
        @include ratio-alt-only {
          background: $grey-medium-light;
        }
      }
    }
    .info-wrap {
      background: $green-home;

      .main-nav {
        a {
          @include untitled(16px);
          line-height: 2.5;

          @include desktop-large-only {
            @include untitled(18px);
          }
          @include desktop-xl-only {
            @include untitled(18px);
          }

          @include desktop-small-only {
            @include untitled(12px);
          }

          @include desktop-ipad-only {
            @include untitled(12px);
            line-height: 2.5;
          }
        }
      }
      .open-wrap {
        color: $white;

        .long {
          @media screen and (max-height: 670px) {
            @include untitled(48px);
            line-height: 1.1574233551;
            font-weight: 400;
          }
          @media screen and (max-height: 640px) {
            @include untitled(40px);
            line-height: 1.1574233551;
            font-weight: 400;
          }
          @media screen and (max-height: 600px) {
            @include untitled(36px);
            line-height: 1.1574233551;
            font-weight: 400;
          }
        }

        h2 {
          @include untitled(58px);
          line-height: 1.1574233551;
          font-weight: 400;
        }
      }
    }
    .feat-exhibition {
      .exhibitons-on-now-outer-wrap {
        background: rgba($white, 0.64);

        .exhibitons-on-now-wrap {
          &.resizing {
            opacity: 0;
          }
          &.size-1 {
            .text {
              h2 {
                @include untitled(54px);
              }
              .date {
                @include untitled(25px);
              }
            }
          }
          &.size-2 {
            .text {
              h2 {
                @include untitled(48px);
              }
              .date {
                @include untitled(23px);
              }
            }
          }
          &.size-3 {
            .text {
              h2 {
                @include untitled(40px);
              }
              .date {
                @include untitled(22px);
              }
            }
          }
          &.size-4 {
            .text {
              h2 {
                @include untitled(37px);
              }
              .date {
                @include untitled(21px);
              }
            }
          }
          &.size-5 {
            .text {
              h2 {
                @include untitled(34px);
              }
              .date {
                @include untitled(19px);
              }
            }
          }
          &.size-6 {
            .text {
              h2 {
                @include untitled(32px);
              }
              .date {
                @include untitled(18px);
              }
            }
          }
          &.size-7 {
            .text {
              h2 {
                @include untitled(30px);
              }
              .date {
                @include untitled(17px);
              }
            }
          }
          &.size-8 {
            .text {
              h2 {
                @include untitled(26px);
              }
              .date {
                @include untitled(15px);
              }
            }
          }
          .text {
            color: inherit;

            h2 {
              @include untitled(67px);
              word-break: break-word;
            }
            .date {
              @include untitled(27px);
              font-weight: 300;
            }
          }
        }
      }
      @include desktop-only {
        position: absolute;
        width: calc(((100vw - 15px) / #{$total-cols}) * (#{3 + 17}));
        top: 0;
        left: calc(100% - ((100vw - 15px) / #{$total-cols}) * 3 - 13px);
        height: 100%;
        cursor: pointer;
        z-index: 1;

        &:before {
          position: absolute;
          display: block;
          content: "";
          top: 0;
          left: 100%;
          width: 100%;
          height: 100%;
          background: inherit;
        }
        &:after {
          position: absolute;
          display: block;
          content: "";
          top: 0;
          left: 100%;
          width: 100%;
          height: 100%;
          background: rgba($white, 0.64);
        }

        @include desktop-small-only {
          width: calc(((100vw - 15px) / #{$total-cols}) * (#{2.5 + 16.5}));
          left: calc(100% - ((100vw - 15px) / #{$total-cols}) * 2.5 - 13px);
        }
        @include desktop-medium-only {
          width: calc(((100vw - 15px) / #{$total-cols}) * (#{2 + 14}));
          left: calc(100% - ((100vw - 15px) / #{$total-cols}) * 2 - 13px);
        }
        @include desktop-large-only {
          width: calc(((100vw - 15px) / #{$total-cols}) * (#{2 + 12}));
          left: calc(100% - ((100vw - 15px) / #{$total-cols}) * 2 - 13px);
        }
        @include desktop-xl-only {
          @include ib-grid(1.5 + 10, $gutter: 0);
          left: calc(100% - ((100% / #{$total-cols}) * 1.5));
          // width: calc(((100vw - 15px) / #{$total-cols}) * (#{1.5 + 10 / 2}));
          // left: calc(100% - ((100vw - 15px) / #{$total-cols}) * 1.5 - 15px);
        }

        .exhibitons-on-now-outer-wrap {
          position: relative;
          height: 100vh;

          .exhibitons-on-now-wrap {
            position: absolute;
            display: inline-block;
            vertical-align: top;
            height: calc(100% - #{$bottom-bar-height + $footer-height});
            left: 0;
            top: 0;
            // padding-top: 98px;
            width: calc(((100vw - 15px) / #{$total-cols}) * 14);
            margin-left: calc(((100vw - 15px) / #{$total-cols}) * 3 + 15px);

            padding-top: $footer-height;

            @media screen and (min-width: 1065px) {
              height: calc(100% - #{$bottom-bar-height + $footer-max-height});
            }

            @include desktop-ipad-only {
              width: calc(((100vw - 15px) / #{$total-cols}) * 13.5);
            }
            @include desktop-small-only {
              width: calc(((100vw - 15px) / #{$total-cols}) * 13);
              margin-left: calc(((100vw - 15px) / #{$total-cols}) * 3 + 15px);
            }
            @include desktop-medium-only {
              width: calc(((100vw - 15px) / #{$total-cols}) * 11.5);
              margin-left: calc(((100vw - 15px) / #{$total-cols}) * 2 + 15px);
            }
            @include desktop-large-only {
              width: calc(((100vw - 15px) / #{$total-cols}) * 9.5);
              margin-left: calc(((100vw - 15px) / #{$total-cols}) * 2 + 15px);
            }
            @include desktop-xl-only {
              width: calc((1921px / #{$total-cols}) * 8.5);
              margin-left: calc((1921px / #{$total-cols}) * 1.5);
            }
            &.size-1 {
              padding-top: 104px;

              .text {
                margin-bottom: #{$bottom-bar-height + 35px};

                .date {
                  margin-bottom: 35px;
                }
              }
            }
            &.size-2 {
              padding-top: 109px;

              .text {
                margin-bottom: #{$bottom-bar-height + 30px};

                h2 {
                  margin-bottom: 6px;
                }
                .date {
                  margin-bottom: 30px;
                }
              }
            }
            &.size-3 {
              padding-top: 112px;

              .text {
                margin-bottom: #{$bottom-bar-height + 27px};

                h2 {
                  margin-bottom: 5px;
                }
                .date {
                  margin-bottom: 27px;
                }
              }
            }
            &.size-4 {
              padding-top: 115px;

              .text {
                margin-bottom: #{$bottom-bar-height + 25px};

                h2 {
                  margin-bottom: 4px;
                }
                .date {
                  margin-bottom: 25px;
                }
              }
            }
            &.size-5 {
              padding-top: 72px;

              .text {
                margin-bottom: #{$bottom-bar-height + 23px};

                h2 {
                  margin-bottom: 3px;
                }
                .date {
                  margin-bottom: 23px;
                }
              }
            }
            &.size-6 {
              padding-top: 73px;

              .text {
                margin-bottom: #{$bottom-bar-height + 21px};

                h2 {
                  margin-bottom: 2px;
                }
                .date {
                  margin-bottom: 21px;
                }
              }
            }
            &.size-7 {
              padding-top: 75px;

              .text {
                margin-bottom: #{$bottom-bar-height + 19px};

                h2 {
                  margin-bottom: 1px;
                }
                .date {
                  margin-bottom: 19px;
                }
              }
            }
            &.size-8 {
              padding-top: 81px;

              .text {
                margin-bottom: #{$bottom-bar-height + 12px};

                h2 {
                  margin-bottom: 0;
                }
                .date {
                  margin-bottom: 12px;
                }
              }
            }
            .text {
              position: absolute;
              width: 100%;
              top: 0;
              left: 0;
              padding-bottom: #{$bottom-bar-height + 45px};

              &:last-child {
                visibility: hidden;
                pointer-events: none;
              }

              // &.long {
              //   h2 {
              //     margin-bottom: 10px;
              //   }
              // }
              h2 {
                margin: 0 0 7px;
              }
              .date {
                margin-bottom: 45px;

                // @media screen and (max-height: 780px) {
                //   margin-bottom: 20px;
                // }
              }
              :last-child {
                margin: 0;
              }
            }
          }
        }
      }
    }
    @include desktop-only {
      height: 100%;

      @include desktop-large-only {
        @include ib-grid(31, $gutter: 0);
        @include prefix(5, $gutter: 0);
      }
      @include ratio-alt-only {
        @include ib-grid($total-cols, $gutter: 0);
        @include prefix(0, $gutter: 0);
      }
      @include desktop-xl-only {
        position: relative;
        width: #{$max-width};
        margin-left: calc(100% - #{$max-width});
      }
    }
  }
  .shapes-wrap {
    background: $green-dark;
  }
  @include desktop-only {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10;
    visibility: hidden;
    pointer-events: none;

    .home-inner {
      & > .logo-wrap {
        position: relative;
        height: $header-height;
        width: 100vw;

        .state-header-slim & {
          height: $header-slim-height;
        }
        @include desktop-large-only {
          @include ib-grid(36, 31, $gutter: 0);
          @include prefix(-5, 31, $gutter: 0);
        }
        @include ratio-alt-only {
          width: 100vw;
          margin-left: 0;
        }
        @include desktop-xl-only {
          width: 100vw;
          margin-left: calc(#{$max-width} - 100vw);
        }
        .image-wrap {
          position: absolute;
          @include ib-grid(6);
          margin-left: calc((100vw / #{$total-cols}) * 1);
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          max-width: 162px;

          @include desktop-xl-only {
            margin-left: calc(
              100vw - #{$max-width} + #{$max-width / $total-cols * 1}
            );
            left: 0;
            right: auto;
            margin-right: 0;
          }
        }
      }
    }
    .info-wrap {
      position: relative;
      height: calc(100vh - #{$header-height});
      padding-top: 40px;

      .state-header-slim & {
        height: calc(100vh - #{$header-slim-height});
      }
      @include desktop-large-only {
        &:before {
          position: absolute;
          @include ib-grid(5, 31, $gutter: 0);
          content: "";
          height: 100%;
          right: 100%;
          top: 0;
          background: $grey-light;
          z-index: 1;
        }
      }
      @include desktop-xl-only {
        &:before {
          position: absolute;
          content: "";
          height: 100%;
          width: calc(100vw - #{$max-width});
          right: 100%;
          top: 0;
          background: $grey-light;
          z-index: 1;
        }
      }
      @media screen and (min-width: 2320px) {
        .state-home-open & {
          &:before {
            width: calc((((400vw + 1px) + #{$max-width}) / 5) - #{$max-width});
          }
        }
        &:before {
          position: absolute;
          content: "";
          height: 100%;
          width: calc((((400vw - 75px) + #{$max-width}) / 5) - #{$max-width});
          left: 0;
          top: 0;
          background: $grey-light;
          transform: translateX(-100%);
          z-index: 1;
        }
      }
      @include ratio-alt-only {
      }

      &:after {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        display: block;
        content: "";
        background: $green-lightest;
        padding-top: $footer-height;

        @media screen and (min-width: 1065px) {
          padding-top: #{$footer-max-height};
        }
      }
      .main-nav {
        @include ib-grid(15);
        @include prefix(1);

        @include desktop-large-only {
          @include ib-grid(14, 31);
          @include prefix(1, 31);
        }
        a {
          display: inline-block;
          margin-right: 12px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
      .open-wrap {
        position: absolute;
        @include ib-grid(12.5);
        margin-left: calc((100vw / #{$total-cols}) * 1);
        left: 0;
        bottom: 0;
        padding-bottom: calc((100vw / #{$total-cols}) * 8 + 45px);

        @media screen and (min-width: 1065px) {
          padding-bottom: calc(#{$footer-max-height} + 45px);
        }
        h2 {
          margin: 0;

          p {
            margin: 0;
          }
        }
      }
    }
    .shapes-wrap {
      position: absolute;
      width: calc(((100vw - 15px) / #{$total-cols}) * 17);
      margin-left: calc(((100vw - 15px) / #{$total-cols}) * 16);
      height: calc(100% - (100vw / #{$total-cols}) * 7.5);
      top: 0;
      left: 0;
      padding: 45px 45px $bottom-bar-height;
      overflow: hidden;

      @media screen and (min-width: 1065px) {
        height: calc(100% - #{$footer-max-height});
      }
      @include desktop-small-only {
        width: calc(((100vw - 15px) / #{$total-cols}) * 16.5);
        margin-left: calc(((100vw - 15px) / #{$total-cols}) * 17);
      }
      @include desktop-medium-only {
        width: calc(((100vw - 15px) / #{$total-cols}) * 14);
        margin-left: calc(((100vw - 15px) / #{$total-cols}) * 20);
      }
      @include desktop-large-only {
        width: calc(((100vw - 15px) / #{$total-cols}) * 12);
        margin-left: calc(((100vw - 15px) / #{$total-cols}) * 22);
      }
      @include ratio-alt-only {
        height: calc(
          100% - ((100vw / #{$total-cols}) * 7.5) - #{$header-height}
        );
        top: #{$header-height};

        .state-header-slim & {
          height: calc(
            100% - ((100vw / #{$total-cols}) * 7.5) - #{$header-slim-height}
          );
          top: #{$header-slim-height};
        }
        @media screen and (min-width: 1065px) {
          height: calc(100% - #{$header-height} - #{$footer-max-height});
          top: #{$header-height};

          .state-header-slim & {
            height: calc(100% - #{$header-slim-height} - #{$footer-max-height});
            top: #{$header-slim-height};
          }
        }
      }
      @include desktop-xl-only {
        @include ib-grid(10, $gutter: 0);
        @include prefix(24.5, $gutter: 0);
        height: calc(100% - #{$footer-max-height});
      }
      .image-wrap {
        position: relative;
        visibility: hidden;

        &.loaded {
          visibility: inherit;
        }
        .shape-cover {
          position: absolute;
          width: 100%;
          height: $bottom-bar-height;
          bottom: 0;
          left: 0;
          background: $green-dark;
        }
      }
    }
    .bottom-bar {
      position: absolute;
      width: calc(((100vw - 15px) / #{$total-cols}) * 17);
      margin-left: calc(((100vw - 15px) / #{$total-cols}) * 16);
      bottom: #{$footer-height};
      left: 0;
      padding-top: $bottom-bar-height;
      background: $black-dark;
      z-index: 1;

      .state-scrollbar-active & {
        bottom: #{$footer-height-scroll};
      }
      @media screen and (min-width: 1065px) {
        bottom: #{$footer-max-height + 1px};
      }
      @include desktop-small-only {
        width: calc(((100vw - 15px) / #{$total-cols}) * 16.5);
        margin-left: calc(((100vw - 15px) / #{$total-cols}) * 17);
      }
      @include desktop-medium-only {
        width: calc(((100vw - 15px) / #{$total-cols}) * 14);
        margin-left: calc(((100vw - 15px) / #{$total-cols}) * 20);
      }
      @include desktop-large-only {
        width: calc(((100vw - 15px) / #{$total-cols}) * 12);
        margin-left: calc(((100vw - 15px) / #{$total-cols}) * 22);
      }
      @include desktop-xl-only {
        @include ib-grid(10, $gutter: 0);
        @include prefix(24.5, $gutter: 0);
      }
    }
  }
}

.home-footer {
  background: $green-medium-light;
  color: $footer-text-color;

  .footer-inner {
    background: $green-medium-light;
  }
  .text {
    @include untitled(12px);
    line-height: 1.3076923077;

    strong {
      @include untitled(11px);
    }
    a {
      color: inherit;
    }
  }
  .subscribe-link {
    @include stealth-link;
    cursor: pointer;
  }
  @include desktop-only {
    position: fixed;
    width: calc(100vw - 15px);
    left: 0;
    bottom: 0;
    padding-top: $footer-height;
    z-index: 15;
    display: none;

    .state-scrollbar-active & {
      padding-top: $footer-height-scroll;
    }
    &:after {
      position: absolute;
      display: block;
      content: "";
      width: 15px;
      height: 100%;
      top: 0;
      left: 100%;
      background: $green-medium-light;
    }

    @media screen and (min-width: 1065px) {
      padding-top: #{$footer-max-height + 1px};
    }
    .overlay-subscribe-wrap {
      .subscribe-inner {
        & > p.message {
          padding-left: 0;
        }
      }
    }
    .footer-inner {
      position: absolute;
      height: 100%;
      width: 100%;
      padding: 35px 0 80px;
      top: 0;
      left: 0;
      z-index: 2;

      @include desktop-large-only {
        @include ib-grid(31, $gutter: 0);
        @include prefix(5, $gutter: 0);
        padding-bottom: 55px;
        left: 0;

        &:before {
          position: absolute;
          content: "";
          background: inherit;
          display: block;
          @include ib-grid(5, 31, $gutter: 0);
          height: 100%;
          top: 0;
          right: 100%;
          z-index: 2;
        }
      }
      @include ratio-alt-only {
        width: 100%;
        margin-left: 0;
        top: 0;
        left: 0;

        &:before {
          display: none;
        }
      }
      @include desktop-xl-only {
        padding-bottom: 55px;
        width: #{$max-width - 15px};
        left: auto;
        right: 0;

        &:before {
          position: absolute;
          content: "";
          background: inherit;
          display: block;
          width: calc(100vw - #{$max-width - 15px});
          height: 100%;
          top: 0;
          right: 100%;
          z-index: 2;
        }
      }
      &:after {
        position: absolute;
        content: "";
        background: $green-medium-light;
        display: block;
        width: 15px;
        height: 100%;
        top: 0;
        left: 100%;
        z-index: 2;
      }

      .contact-wrap {
        @include ib-grid(14);
        @include prefix(1);

        .text {
          &:nth-child(1) {
            @include ib-grid(7, 14);
          }
          &:nth-child(2) {
            @include ib-grid(6, 14);
            @include prefix(1, 14);
          }
        }
        @include desktop-large-only {
          @include ib-grid(14, 31);
          @include prefix(1, 31);
        }
        @include ratio-alt-only {
          @include ib-grid(14);
          @include prefix(1);
        }
      }
      .social-wrap {
        @include ib-grid(6);
        @include prefix(1);

        @include desktop-large-only {
          @include ib-grid(6, 31);
          @include prefix(1, 31);
        }
        @include ratio-alt-only {
          @include ib-grid(6);
          @include prefix(1);
        }
        .subscribe-wrap {
          margin-bottom: 28px;
        }
        & > .text {
          a {
            display: inline-block;
            margin-right: 10px;
          }
        }
      }
      .sponsor-wrap {
        @include ib-grid(7);
        @include prefix(1);

        @include desktop-large-only {
          @include ib-grid(7, 31);
          @include prefix(1, 31);
        }
      }
    }
  }
  @include mobile-only {
    display: none;
  }
}
